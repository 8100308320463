<template>
  <div class="mobile-footer-menus">
     <div class="item" :class="['/index', '/home','/'].includes($route.path) ? 'active-item' : ''" @click="changeMenu('/')">
       <div class="icon">
         <img src="@/assets/foot/home-2.png" v-if="['/index', '/home','/'].includes($route.path) ? 'active-item' : ''"/>
         <img src="@/assets/foot/home-1.png" v-else/>
       </div>
       <div class="word">Home</div>

     </div>
     <div class="item" :class="$route.fullPath == '/maxdata' ? 'active-item' : ''" @click="changeMenu('/maxdata')">
       <div class="icon">
         <img src="@/assets/foot/search-2.png" v-if="activeMenu == '/maxdata'"/>
         <img src="@/assets/foot/search-1.png" v-else/>
       </div>
       <div class="word">MaxData</div>
     </div>
     <div class="item" :class="$route.fullPath == '/maxtrade' ? 'active-item' : ''" @click="changeMenu('/maxtrade')">
       <div class="icon">
         <img src="@/assets/foot/trade-2.png" v-if="activeMenu == '/maxtrade'"/>
         <img src="@/assets/foot/trade-1.png" v-else/>
       </div>
       <div class="word">MaxTrade</div>
     </div>
    <div class="item" :class="$route.fullPath == '/max-sales' ? 'active-item' : ''" @click="changeMenu('/max-sales')">
      <div class="icon">
        <img src="@/assets/foot/sale-2.png" v-if="activeMenu == '/max-sales'"/>
        <img src="@/assets/foot/sale-1.png" v-else/>
      </div>
      <div class="word">MaxSales</div>
    </div>
     <div class="item" :class="$route.fullPath == '/me' ? 'active-item' : ''" @click="changeMenu('/me')">
       <div class="icon">
         <img src="@/assets/foot/me-2.png" v-if="$route.fullPath == '/me' ? 'active-item' : ''"/>
         <img src="@/assets/foot/me-1.png" v-else/>
       </div>
       <div class="word">Me</div>
     </div>
  </div>
</template>

<script>

export default {
  props: [],
  data() {
    return {
      activeMenu: '/'
    };
  },
  mounted() {

  },
  methods: {
    changeMenu(menu){
      this.$router.push(menu);
      this.activeMenu = menu;
    }

  }
};
</script>
<style lang="less">
  .mobile-footer-menus{
    display: flex;
    justify-content: space-between;
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 60px;
    background: #120D21;
    padding-left: 20px;
    padding-right: 20px;
    align-items: center;
    border-top: 1px solid #4d4d4d;
    .item{
      margin-top: 5px;
      .word{
        color: #fff;
        font-weight: 500;
        font-size: 12px;
        margin-top: 5px;
      }
      .icon{
        display: flex;
        justify-content: center;
        img{
          width: 22px;
          height: 22px;
        }
      }

    }
    .active-item{
      .word{
        color: #FF4835;
        font-weight: 600;
      }
    }
  }
</style>
