import { render, staticRenderFns } from "./CHeader-mobile.vue?vue&type=template&id=1d47c1e8&scoped=true&"
import script from "./CHeader-mobile.vue?vue&type=script&lang=js&"
export * from "./CHeader-mobile.vue?vue&type=script&lang=js&"
import style0 from "./CHeader-mobile.vue?vue&type=style&index=0&lang=less&"
import style1 from "./CHeader-mobile.vue?vue&type=style&index=1&id=1d47c1e8&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1d47c1e8",
  null
  
)

export default component.exports