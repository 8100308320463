<template>
  <div>
    <Modal class="pop-register-success" footer-hide v-model="showLogin">
      <div class="pop-login-main">
        <div class="top">
          <div class="title">Welcome to Connexima!</div>
        </div>
        <div class="desc-top">
          Just one last step before you start: You need to confirm your account.
          We have sent a verification mail to the email address you provided
          during registration.
        </div>
        <div class="desc-bottom">
          If that doesn't work, you may click on the button below after 30
          seconds. We will have it resend to you.
        </div>

        <div class="resend search-button" @click="reSend">
          Re-send the verification email
        </div>

        <div class="desc-last">
          If you have any questions, simply reach out to us through clicking
          <a href="/contact-us" target="_blank" style="color:#f57f45">here</a>.
        </div>
        <div class="close search-button" @click="close">Close</div>
      </div>
    </Modal>
  </div>
</template>

<script>
export default {
  props: [],
  data() {
    return {
      showLogin: false,
      token: ""
    };
  },
  mounted() {},
  methods: {
    close() {
      this.showLogin = false;
    },
    reSend() {
      this.$store
        .dispatch("user/reSendVerifyEmail", { token: this.token })
        .then(res => {
          console.log(res);
          this.$Message.success("Success");
        })
        .catch(({ message }) => {
          this.$Message.error(message);
        })
        .finally(() => {});
    }
  }
};
</script>
<style lang="less" scoped>
.pop-register-success {
  .title {
    font-weight: 700;
    font-size: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .desc-top {
    font-weight: 500;
    font-size: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    padding-left: 10px;
    padding-right: 10px;
  }
  .desc-bottom {
    font-weight: 500;
    font-size: 15px;
    margin-top: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 10px;
    padding-right: 10px;
  }
  .resend {
    color: #f57f45;
    font-weight: 500;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px auto 20px auto;
    font-weight: 700;
  }
  .close {
    width: 80px;
    height: 33px;
    background: #f57f45;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 30px auto 20px auto;
    border-radius: 5px;
  }
}
</style>

<style lang="less">
.pop-register-success {
  .ivu-modal {
    width: 620px !important;
  }
}
</style>
