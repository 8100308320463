<template>
  <div>
    <Modal class="pop-login" footer-hide v-model="showLogin">
      <div class="pop-login-main">
        <div class="top">
          <div class="title">Welcome back</div>
        </div>
        <div class="desc">
          Welcome back! Sign in to Connexima.
        </div>
        <div class="email content-input">
          <div class="title">Email</div>
          <div class="input">
            <input
              placeholder="Enter your email"
              type="text"
              v-model="loginForm.email"
            />
          </div>
        </div>
        <div class="password content-input">
          <div class="title">Password</div>
          <div class="input">
            <input
              placeholder="Enter your password"
              type="password"
              v-model="loginForm.password"
            />
          </div>
        </div>


        <div
          :class="[
            'login-submit',
            'search-button',
            { 'login-submit-waiting': loginSubmitWaiting }
          ]"
          @click="login"
        >
          {{ signInText }}
        </div>
        <div class="forgot">
          <router-link to="/user-action/forgot-password" target="_blank">
            Forgot password?
          </router-link>
        </div>
        <div class="sign-in-with">
          <div class="word">
            <div class="left-inline"></div>
            <div class="word-content">
              or sign in with
            </div>
            <div class="left-inline"></div>
          </div>
          <div class="icons">
            <div class="icon" @click="socialLogin('google')">
              <img
                src="@/assets/register/google.png"
                v-if="!googleLoginLoading"
              />
              <img src="@/assets/loading.gif" v-else />
            </div>
            <div class="icon" @click="socialLogin('facebook')">
              <img
                src="@/assets/register/facebook.png"
                v-if="!facebookLoginLoading"
              />
              <img src="@/assets/loading.gif" v-else />
            </div>
            <div class="icon" @click="socialLogin('linkedin')">
              <img
                src="@/assets/register/linkedin.png"
                v-if="!linkedinLoginLoading"
              />
              <img src="@/assets/loading.gif" v-else />
            </div>
          </div>
        </div>
        <div class="register" @click="register">
          Not a member?
          <span class="search-button">Register here</span>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
// import api from "@/api";
// const { _queryAds, _hisAds } = api;

export default {
  props: [],
  data() {
    return {
      loginForm: { role: "", email: "", password: "" },
      showLogin: false,
      signInText: "Login",
      loginSubmitWaiting: false,
      googleLoginLoading: false,
      facebookLoginLoading: false,
      linkedinLoginLoading: false
    };
  },
  mounted() {},
  methods: {
    socialLogin(type) {
      if (type === "google") {
        this.googleLoginLoading = true;
      }
      if (type === "facebook") {
        this.facebookLoginLoading = true;
      }
      if (type === "linkedin") {
        this.linkedinLoginLoading = true;
      }
      this.$store
        .dispatch("user/auth", {
          type: type
        })
        .then(res => {
          window.location.href = res;
        })
        .catch(({ message }) => {
          this.$Message.error(message);
        })
        .finally(() => {
          if (type === "google") {
            this.googleLoginLoading = false;
          }
          if (type === "facebook") {
            this.facebookLoginLoading = false;
          }
          if (type === "linkedin") {
            this.linkedinLoginLoading = false;
          }
        });
    },
    register() {
      this.$emit("register");
    },
    changeRole(role) {
      this.loginForm.role = role;
    },
    login() {
      const {
        loginForm: { email, password, role }
      } = this;
      // if (role == "") {
      //   this.$Message.error("Please choose account type!");
      //   return;
      // }
      if (email == "" || password == "") {
        this.$Message.error("Please enter your email and password!");
        return;
      }
      this.loading = true;
      this.loginSubmitWaiting = true;
      this.signInText = "Logging in";
      this.$store
        .dispatch("user/login", {
          email,
          password,
          role
        })
        .then(res => {
          this.loginSubmitWaiting = false;
          this.signInText = "Login";
          this.$store.dispatch("chat/login");
          this.$Message.success("Login successful!");
          this.showLogin = false;
        })
        .catch(({ message }) => {
          this.signInText = "Login";
          this.loginSubmitWaiting = false;
          this.$Message.error(message);
        })
        .finally(() => {

        });
    }
  }
};
</script>
<style lang="less" scoped>
.pop-login-main {
  /*height: 550px;*/
  display: flex;
  flex-direction: column;
  align-items: center;
  .top {
    .title {
      font-weight: 600;
      font-size: 26px;
      line-height: 37px;
      display: flex;
      align-items: center;
      text-align: center;

      /* Black */

      color: #222222;
      margin-top: 5px;
    }
    .close {
    }
  }
  .desc {
    margin: 10px auto 10px auto;
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
    /* or 144% */

    display: flex;
    align-items: center;
    text-align: center;

    /* Gray 3 */

    color: #828282;
  }
  .type {
    .type-title {
      font-weight: 600;
      font-size: 20px;
      line-height: 28px;
      /* identical to box height, or 140% */

      display: flex;
      align-items: center;

      color: #000000;
      margin-bottom: 10px;
    }
    .types {
      display: flex;
      justify-content: space-between;
      width: 470px;
      .active-buyer {
        background: #f99d20;
        .buyer-name {
          color: #f2f2f2 !important;
        }
      }
      .buyer {
        display: flex;
        width: 230px;
        height: 50px;
        border: 1px solid #f57f45;
        box-sizing: border-box;
        border-radius: 8px;
        .buyer-icon {
          display: flex;
          flex-direction: column;
          justify-content: center;
          margin-left: 15px;
        }
        .buyer-name {
          font-weight: 600;
          font-size: 16px;
          line-height: 20px;
          /* identical to box height */

          display: flex;
          align-items: center;
          text-align: center;

          /* Black */

          color: #222222;

          /* Inside Auto Layout */

          flex: none;
          order: 1;
          flex-grow: 0;
          margin: 0px 16px;
        }
      }

      .active-seller {
        background: #f99d20;
        .seller-name {
          color: #f2f2f2 !important;
        }
      }
      .seller {
        display: flex;
        width: 230px;
        height: 50px;
        border: 1px solid #f57f45;
        box-sizing: border-box;
        border-radius: 8px;
        .seller-icon {
          display: flex;
          flex-direction: column;
          justify-content: center;
          margin-left: 15px;
        }
        .seller-name {
          font-weight: 600;
          font-size: 16px;
          line-height: 20px;
          /* identical to box height */

          display: flex;
          align-items: center;
          text-align: center;

          /* Black */

          color: #222222;

          /* Inside Auto Layout */

          flex: none;
          order: 1;
          flex-grow: 0;
          margin: 0px 16px;
        }
      }
    }
  }
  .content-input {
    .title {
      font-weight: 600;
      font-size: 18px;
      line-height: 28px;
      /* identical to box height, or 140% */

      display: flex;
      align-items: center;

      color: #000000;
      margin: 20px auto 10px auto;
    }
    .input {
      input {
        width: 470px;
        height: 50px;
        left: 527px;
        top: 702px;

        /* Gray 6 */

        background: #f2f2f2;
        border-radius: 8px;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        /* or 150% */

        display: flex;
        align-items: center;

        /* Gray 3 */

        color: #828282;
        border: none;
        outline: none;
        padding-left: 10px;
      }
      input:focus {
        background: #fff;
        border: 1px solid #f57f45;
      }
    }
  }
  .forgot {
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: right;
    width: 470px !important;

    a {
      font-weight: 600;
      font-size: 16px;
      line-height: 26px;
      /* or 162% */

      /* Gray 1 */

      color: #666;
      margin: 16px 0;
    }
  }
  .login-submit {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px 24px;
    width: 470px;
    height: 48px;
    background: #f57f45;
    /* Shadow */

    box-shadow: 4px 4px 20px rgba(34, 34, 34, 0.1);
    border-radius: 8px;
    font-weight: 700;
    color: #ffffff;
    text-align: center;
    /* padding-top: 10px; */
    cursor: pointer;
    border: none;
    margin-top: 30px;
    font-size: 16px;
  }
  .login-submit-waiting {
    opacity: 0.7;
  }
  .register {
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    /* or 162% */

    display: flex;
    align-items: center;
    text-align: center;

    /* Gray 3 */

    color: #828282;
    span {
      color: #222222;
      font-weight: 600;
      margin-left: 5px;
    }
    margin-top: 33px;
    .register-here {
      color: #222222;
      font-weight: 600;
      margin-left: 5px;
    }
  }
}
.sign-in-with {
  position: relative;
  top: 15px;
  .word {
    display: flex;
    justify-content: space-between;
    width: 470px;
    .left-inline {
      height: 1px;
      background: #ddd;
      width: 160px;
      position: relative;
      top: 10px;
    }
  }
  .icons {
    display: flex;
    width: 185px;
    justify-content: space-between;
    margin: 15px auto 0 auto;
    .icon {
      cursor: pointer;
      img {
        width: 27px;
      }
    }
  }
}
@media (max-width: 800px) {
  .content-input {
    width: 90%;
    .input input {
      width: 100% !important;
      height: 38px !important;
    }
  }
  .pop-login-main {
    height: fit-content;
    .top{
      .title{
        font-size: 20px;
        line-height: 40px;
      }


    }
    .desc{
      font-size: 13px;
      line-height: 20px;
    }
    .content-input{
      .title{
        font-size: 16px;
        line-height: 24px;
      }
    }
    .login-submit {
      width: 90%;
      height: 38px;
    }
    .forgot {
      width: 90% !important;
      justify-content: flex-end !important;
    }
    .sign-in-with {
      width: 90%;
      .word {
        width: 100%;
        .left-inline {
          width: 80px;
        }
      }
    }
  }
}
</style>

<style lang="less">
.pop-login {
  .ivu-modal {
    width: 570px !important;
  }
}
@media (max-width: 767px) {
  .pop-login {
    .ivu-modal {
      width: 90% !important;
      margin: 0 auto;
    }
  }
}
@media sreen and (min-width: 769px), sreen and (max-width: 1025px) {
  .pop-login {
    .ivu-modal {
      margin: 0 auto;
      /*width: 50px;*/
    }
  }
}
</style>
