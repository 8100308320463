<template>
  <div class="layout router-transitions">
    <Layout class="wide">
      <c-header />

      <Content>
        <div class="notfound">
          <div class="mb-20">404 Not Found</div>
          <router-link to="/">
            <Button type="primary">Home</Button>
          </router-link>
        </div>
      </Content>
    </Layout>
  </div>
</template>

<script>
import CHeader from "@/layout/CHeader";

export default {
  components: {
    CHeader
  },
  data() {
    return {};
  }
};
</script>

<style lang="less" scoped>
.layout {
  background: #e9eaee;
  height: 100%;
  .wide {
    // width: 1280px;
    // margin: 0 auto;
    // min-height: 100vh;
    // min-width: 1080px;
    height: 100%;
    background: #fff;

    .notfound {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      font-size: 20px;
      flex-direction: column;
    }
  }
}
</style>
