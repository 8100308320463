<template>
  <div class="layout router-transitions">
    <Layout class="wide">
      <c-header />

      <router-view></router-view>
    </Layout>
  </div>
</template>

<script>
import CHeader from "@/layout/CHeader";

export default {
  components: {
    CHeader
  },
  data() {
    return {
      activeName: this.$route.path
    };
  },

  watch: {
    $route(newRoute) {
      this.activeName = newRoute.path;
    }
  },
  methods: {}
};
</script>

<style lang="less" scoped>
.layout {
  background: #e9eaee;
  height: 100%;
  .wide {
    // width: 1280px;
    // margin: 0 auto;
    // min-height: 100vh;
    // min-width: 1080px;
    height: 100%;
    background: #fff;
    .silder {
      background: #fff;
      ul {
        height: 100%;
        li {
          border-bottom: 1px solid #ccc;
        }
      }
    }
    .content {
      // padding: 20px;
      // background: #fff;
    }
  }
}
</style>
