<template>
  <router-view />
</template>

<script>
export default {
  updated() {
    // Remove loading state
    setTimeout(() => document.body.classList.remove("app-loading"), 1);
    window.ga("set", "page", this.$route.fullPath);
    window.ga("send", "pageview");
  }
};
</script>

<style lang="scss">
html *,
body * {
  /*font-family: Brandon Grotesque !important;*/
  /*font-size: 16px;*/
}
.font-black {
  font-family: "Montserrat";
  font-weight: 700;
}
.font-medium {
  font-family: "Montserrat";
  font-weight: 600;
}
.font-bold {
  font-family: "Montserrat";
  font-weight: 700;
}
.font-medium-2 {
  font-family: "Montserrat";
        font-weight: 600;
}
.ivu-form .ivu-form-item-label {
  font-size: 16px !important;
}
.ivu-select-input {
  font-size: 16px !important;
}
.ivu-input {
  font-size: 16px !important;
}
.flex {
  display: flex;
}
</style>
