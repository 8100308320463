<template>
  <div v-if="mobileWidth">
    <mobile />
  </div>
  <div class="container router-transitions" v-else>
    <div class="top sc-width">
      <router-link to="/" class="logo">
        <img src="@/assets/logo.png" alt="" />
      </router-link>
      <div class="sign">
        <div class="tips">Already a member?</div>
        <router-link to="/user-action/login">
          <div class="button">Sign In</div>
        </router-link>
      </div>
    </div>

    <router-view></router-view>
  </div>
</template>
<script>
import mobile from "./LayoutBlank-mobile";
export default {
  components: {
    mobile
  },
  data() {
    return {
      mobileWidth: window.innerWidth < 800
    };
  }
};
</script>
<style lang="less" scoped>
.sc-width {
  width: 1200px;
}
.container {
  // min-height: 100vh;
  height: 100%;
  position: relative;
  .top {
    margin: 0 auto;
    display: flex;
    justify-content: space-between;

    .logo {
      font-size: 20px;
      padding: 20px 20px;
      display: inline-block;
      color: #000 !important;
      /*position: absolute;*/
      top: 0;
      left: 0;
      z-index: 1;
      img {
        height: 30px;
        display: block;
      }
    }
    .sign {
      display: flex;
      margin-top: 20px;
      .tips {
        font-size: 16px;

        font-weight: 500;
        color: #666666;
        line-height: 18px;
        padding: 6px 30px 0 0;
      }
      .button {
        width: 100px;
        height: 30px;
        background: #ef7f21;
        border-radius: 5px;
        font-size: 16px;

        font-weight: 700;
        color: #ffffff;
        line-height: 18px;
        text-align: center;
        padding-top: 6px;
      }
    }
  }
}
</style>
