import api from "@/api";
const { _createSubscribe } = api;

const state = {
  email: ""
};

const mutations = {
  common(state, payload) {
    Object.keys(payload).map(key => {
      state[key] = payload[key];
    });
  }
};

const actions = {
  subscribe({ commit }, email) {
    return _createSubscribe({ email }).then(response => {
      const { data } = response;
      console.log(data);
      commit("common", { message: "success" });
    });
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
