<template>
  <div class="layout router-transitions">
    <Layout class="wide">
      <c-header />

      <Layout>
        <!-- <Sider class="silder" hide-trigger>
          <div class="menus">
            <div
              @click="goToPage(item.path)"
              :class="['menu', { active: activeName.startsWith(item.path) }]"
              v-for="item in menus"
              :key="item.path"
            >
              {{ item.name }}
            </div>
          </div>
        </Sider> -->

        <Content class="content">
          <router-view></router-view>
        </Content>
      </Layout>

      <c-footer />
    </Layout>
  </div>
</template>

<script>
import CHeader from "@/layout/CHeader";
import CFooter from "@/layout/CFooter";
import { mapState } from "vuex";

export default {
  components: {
    CHeader,
    CFooter
  },
  computed: {
    ...mapState("user", ["role"]),
    menus() {
      if (!this.role) {
        return [
          {
            name: "Exhibitors",
            path: "/exhibitors"
          }
        ];
      }
      if (this.role === "exhibitor") {
        return [
          {
            name: "Dashboard",
            path: "/home"
          },
          {
            name: "Company Profile",
            path: "/company-profile"
          },
          {
            name: "Conferences",
            path: "/conferences"
          },
          {
            name: "Forum",
            path: "/forum"
          }
        ];
      } else {
        return [
          {
            name: "Dashboard",
            path: "/home"
          },
          {
            name: "Company Profile",
            path: "/company-profile"
          },
          {
            name: "Products",
            path: "/products"
          },
          {
            name: "Exhibitors",
            path: "/exhibitors"
          },
          {
            name: "Conferences",
            path: "/conferences"
          },
          {
            name: "Forum",
            path: "/forum"
          }
        ];
      }
    }
  },
  data() {
    return {
      activeName: this.$route.path
    };
  },
  created() {
    // if (process.env.NODE_ENV === "production") this.menus.splice(-1, 1);
  },
  watch: {
    $route(newRoute) {
      this.activeName = newRoute.path;
    }
  },
  methods: {
    goToPage(path) {
      this.$router.push(path);
    }
  }
};
</script>

<style lang="less" scoped>
.layout {
  background: #f2f2f2;
  height: 100%;
  .wide {
    // width: 1280px;
    // margin: 0 auto;
    // min-height: 100vh;
    // min-width: 1080px;
    height: 100%;
    background: #fff;
    .silder {
      background: #404040;
      .menus {
        .menu {
          cursor: pointer;
          font-size: 14px;
          font-weight: bold;
          padding: 14px;
          color: #848484;
          &.active {
            color: #fff;
            background: rgba(245, 124, 0, 0.6);
          }
        }
      }
    }
    .content {
      // padding: 20px;
      // background: #fff;
      min-height: 600px;
      background: #f2f2f2;
    }
    .footer {
      background: #333;
      padding: 0;
      .link-box {
        padding: 20px;
        .title {
          color: #fff;
          font-weight: bold;
          margin-bottom: 10px;
        }
        .search-box {
          padding-right: 20px;
          .search {
            color: #fff;
            font-weight: bold;
            margin-bottom: 10px;
          }
          .search-input {
            display: flex;
            background: rgba(153, 153, 153, 0.28);
            .input {
              border: none;
              color: #fff;
              padding-left: 10px;
              outline: none;
              background: transparent;
              flex: 1;
            }
            .search-btn {
              padding: 5px 10px;
              color: @primary-color;
              border: 1px solid @primary-color;
            }
          }
        }
        .item {
          color: @primary-color;
        }
        .logo {
          width: 100px;
          margin-top: 20px;
        }
      }
      .copyright {
        text-align: right;
        padding: 10px;
        border-top: 1px solid rgba(255, 255, 255, 0.34);
        color: #ccc;
      }
    }
  }
}
</style>
<style lang="less">
.ivu-layout {
  display: block !important;
}
</style>
