let BASEURL = "https://api-dev.connexima.com/api";
let twilioIdPrefix = "identity-development-";

if (process.env.NODE_ENV === "staging") {
  BASEURL = "https://api-staging.connexima.com/api";
  twilioIdPrefix = "identity-staging-";
}
if (process.env.NODE_ENV === "production") {
  BASEURL = "https://api.connexima.com/api";
  twilioIdPrefix = "identity-production-";
}
module.exports = {
  API_PREFIX: BASEURL,
  twilioIdPrefix
};
