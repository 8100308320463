import Vue from "vue";
import api from "@/api";
import { parseTime } from "@/utils/helper";
const { _queryTwilioToken } = api;

const state = {
  waitingTwilioLoading: true,
  client: null,
  $channelsKV: {},

  curChanneSid: null,
  channelsKV: {},
  channelsSort: []
};

const mutations = {
  chooseCurChannel(state, sid) {
    state.curChanneSid = sid;
  },

  readCurChannel(state) {
    const channel = state.channelsKV[state.curChanneSid];
    channel.newMessageCount = 0;
  },

  setNewChannel(state, item) {
    Vue.set(state.channelsKV, item.sid, {
      sid: item.sid,
      channel_name: item.name,
      company: item.company,

      lastMessageTime: "",
      newMessageCount: 0,
      usersKV: {}
    });
    state.channelsSort.unshift(item.sid);
  },

  setChannel(state, item) {
    Vue.set(state.channelsKV, item.sid, {
      sid: item.sid,
      channel_name: item.friendlyName,
      company: item.attributes.company,

      lastMessageTime: "",
      newMessageCount: item.newMessageCount,
      usersKV: {}
    });
    state.channelsSort.push(item.sid);
  },

  sortChannel(state) {
    const sortArr = Object.keys(state.channelsKV).map(key => {
      return state.channelsKV[key];
    });

    const noNewMessage = sortArr.filter(item => {
      const unixTime = item.lastMessageTime
        ? parseTime(item.lastMessageTime)
        : 0;
      item.unixTime = unixTime;
      return item.newMessageCount === 0;
    });
    const hasNewMessage = sortArr.filter(item => item.newMessageCount !== 0);

    const hasNewMessageKeys = hasNewMessage
      .sort((a, b) => {
        return b.newMessageCount - a.newMessageCount;
      })
      .map(item => {
        return item.sid;
      });

    const noMessageKeys = noNewMessage
      .sort((a, b) => {
        return b.unixTime - a.unixTime;
      })
      .map(item => {
        return item.sid;
      });

    state.channelsSort = [...hasNewMessageKeys, ...noMessageKeys];
  },

  setChannelLastMessageTime(state, { sid, lastMessageTime }) {
    const channel = state.channelsKV[sid];
    channel.lastMessageTime = parseTime(lastMessageTime);
  },

  set$channelsKV(state, data) {
    state.$channelsKV[data.sid] = data;
  },

  setChannelUsersKV(state, { sid, key, value }) {
    const channel = state.channelsKV[sid];
    Vue.set(channel.usersKV, key, value);
  },

  receiveMessage(state, { sid, message }) {
    const channel = state.channelsKV[sid];
    channel.lastMessageTime = message.dateCreated;

    if (state.curChanneSid) {
      if (state.curChanneSid === sid) {
        // empty
      } else {
        // todo notification message push
        channel.newMessageCount++;
      }
    } else {
      // todo notification message push
      channel.newMessageCount++;
    }
  },

  common(state, payload) {
    Object.keys(payload).map(key => {
      state[key] = payload[key];
    });
  }
};

const actions = {
  createGroup({ commit }, $channel) {
    $channel.on("messageAdded", message => {
      commit("receiveMessage", {
        sid: $channel.sid,
        message
      });
    });

    $channel.getMembers().then(members => {
      members.forEach(user => {
        user.getUser().then(userObj => {
          commit("setChannelUsersKV", {
            sid: $channel.sid,
            key: userObj.identity,
            value: { ...userObj.attributes }
          });
        });
      });
    });
  },

  login({ commit }) {
    return _queryTwilioToken({
      type: "chat"
    })
      .then(res => {
        return window.Twilio.Chat.Client.create(res.data.token);
      })
      .then(client => {
        commit("common", { client });
        client.on("tokenExpired", () => {
          _queryTwilioToken({
            type: "chat"
          }).then(res => {
            client.updateToken(res.data.token);
          });
        });
        return client.getUserChannelDescriptors();
      })
      .then(channels => {
        channels.items.forEach(item => {
          let newMessageCount = 0;
          if (item.lastConsumedMessageIndex === null) {
            newMessageCount = item.messagesCount;
          } else {
            newMessageCount =
              item.messagesCount - 1 - item.lastConsumedMessageIndex;
          }
          item.newMessageCount = newMessageCount;
        });

        return Promise.all(
          channels.items.map(item => {
            commit("setChannel", item);

            return item.getChannel().then($channel => {
              commit("set$channelsKV", $channel);

              $channel.on("messageAdded", message => {
                commit("receiveMessage", {
                  sid: $channel.sid,
                  message
                });
                commit("sortChannel");
              });

              if ($channel.lastMessage) {
                commit("setChannelLastMessageTime", {
                  sid: $channel.sid,
                  lastMessageTime: $channel.lastMessage.dateCreated
                });
              }

              $channel.getMembers().then(members => {
                members.forEach(user => {
                  user.getUser().then(userObj => {
                    commit("setChannelUsersKV", {
                      sid: $channel.sid,
                      key: userObj.identity,
                      value: { ...userObj.attributes }
                    });
                  });
                });
              });

              commit("sortChannel");
            });
          })
        );
      })
      .then(() => {
        commit("common", { waitingTwilioLoading: false });
      });
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
