const baseSize = 16
function setRem () {
  if(document.documentElement.clientWidth < 768){
    var scale = document.documentElement.clientWidth / 390
  }else if(document.documentElement.clientWidth <= 1024 && document.documentElement.clientWidth >= 768){
    var scale = document.documentElement.clientWidth / 768
  }else{
    var scale = document.documentElement.clientWidth / 1920
  }
  document.documentElement.style.fontSize = (baseSize * Math.min(scale, 2)).toFixed(3) + 'px'

}
// 初始化
setRem()
// 改变窗口大小时重新设置 rem
window.onresize = function () {
  setRem()
}
