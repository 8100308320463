export function isObject(val) {
  return val != null && typeof val === "object" && Array.isArray(val) === false;
}

export function getToken() {
  return window.localStorage.getItem("userToken");
}
export function setToken(token) {
  window.localStorage.setItem("userToken", token);
}

export function getTokenExpiresSec() {
  return window.localStorage.getItem("tokenExpiresSec");
}
export function setTokenExpiresSec(sec) {
  window.localStorage.setItem("tokenExpiresSec", sec); // sec time
}

export function removeLocalInfo() {
  window.localStorage.clear();
}

export function dataURLtoBlob(dataurl) {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], { type: mime });
}

export function blobToFile(theBlob) {
  theBlob.lastModifiedDate = new Date();
  theBlob.name = "temp";
  return theBlob;
}

export function cleanVueObject(obj) {
  console.log(obj);
}

export function parseTime(time, cFormat) {
  if (arguments.length === 0) {
    return null;
  }
  const format = cFormat || "{d}/{m}/{y} {h}:{i}:{s}";
  let date;
  if (typeof time === "object") {
    date = time;
  } else {
    if (typeof time === "string" && /^[0-9]+$/.test(time)) {
      time = parseInt(time);
    }
    if (typeof time === "number" && time.toString().length === 10) {
      time = time * 1000;
    }
    date = new Date(time);
  }
  const formatObj = {
    y: date.getFullYear(),
    m: date.getMonth() + 1,
    d: date.getDate(),
    h: date.getHours(),
    i: date.getMinutes(),
    s: date.getSeconds(),
    a: date.getDay()
  };
  const time_str = format.replace(/{([ymdhisa])+}/g, (result, key) => {
    const value = formatObj[key];
    // Note: getDay() returns 0 on Sunday
    if (key === "a") {
      return ["日", "一", "二", "三", "四", "五", "六"][value];
    }
    return value.toString().padStart(2, "0");
  });
  return time_str;
}
