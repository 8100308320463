import Vue from "vue";
import VueRouter from "vue-router";
import LayoutBlank from "@/layout/LayoutBlank";
import LayoutEmpty from "@/layout/LayoutEmpty";
import Layout from "@/layout/Layout";
import LayoutWithoutSider from "@/layout/LayoutWithoutSider";
import NotFound from "@/layout/NotFound";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: Layout,
    // redirect: "/index",
    children: [
      {
        path: "",
        component: () => import("@/views/index")
      },
      {
        path: "search",
        component: () => import("@/views/search")
      },
      {
        path: "analysis-companies",
        component: () => import("@/views/analysis-companies")
      },
      {
        path: "analysis-products",
        component: () => import("@/views/analysis-products")
      },
      {
        path: "home",
        component: () => import("@/views/index")
      },
      {
        path: "index",
        component: () => import("@/views/index")
      },
      {
        path: "data-search",
        redirect: "maxdata"
      },
      {
        path: "maxdata",
        component: () => import("@/views/data-search")
      },
      {
        path: "dashboard",
        // component: () => import("@/views/dashboard")
        redirect: "/user-profile/basic",
      },
      {
        path: "test-download-history",
        component: () => import("@/views/test-download-history")
      },
      {
        path: "sourcing-inquiry",
        component: () => import("@/views/sourcing/sourcing-inquiry")
      },
      {
        path: "sourcing-quotation",
        component: () => import("@/views/sourcing/sourcing-quotation")
      },
      {
        path: "company-product-list",
        component: () => import("@/views/company-product-list")
      },
      {
        path: "conferences",
        component: () => import("@/views/conferences")
      },
      {
        path: "forum",
        component: () => import("@/views/forum")
      },
      {
        path: "notifications",
        component: () => import("@/views/notifications")
      },
      {
        path: "conference/:id",
        component: () => import("@/views/conference")
      },
      {
        path: "sourcings",
        component: () => import("@/views/sourcings")
      },
      {
        path: "sell-on-connexima",
        component: () => import("@/views/sell")
      },
      {
        path: "sourcing-inquiries",
        redirect: "maxsource"
      },
      {
        path: "maxsource",
        redirect: "trades"
      },
      {
        path: "trades",
        component: () => import("@/views/sourcingso-inquiry")
      },
      {
        path: "trade-collection",
        component: () => import("@/views/trade-collection")
      },
      {
        path: "maxtrade",
        component: () => import("@/views/sourcingso-inquiry")
      },
      {
        path: "sourcing-quotations",
        redirect: "maxsell"
      },
      {
        path: "maxsell",
        component: () => import("@/views/sourcingso-quotation")
      },
      {
        path: "buy",
        component: () => import("@/views/buy")
      },
      {
        path: "sell",
        component: () => import("@/views/new-sell")
      },
      {
        path: "me",
        component: () => import("@/views/me")
      },
      {
        path: "sourcing/:id",
        component: () =>
          import("@/views//sourcing/sourcing-inquiry-attendee-view")
      },
      {
        path: "sourcing-quotation/:id",
        component: () =>
          import("@/views//sourcing/sourcing-inquiry-exhibitor-view")
      },
      {
        path: "sourcing/:id/edit",
        name: "sourcing-edit",
        component: () =>
          import("@/views/sourcing/sourcing-inquiry-exhibitor-edit")
      },
      {
        path: "sourcing-quotation/:id/edit",
        name: "sourcing-quotation-edit",
        component: () =>
          import("@/views/sourcing/sourcing-inquiry-attendee-edit")
      },
      {
        path: "my-quotations",
        component: () => import("@/views/my-quotations")
      },
      {
        path: "my-inquiries",
        component: () => import("@/views/my-inquiries")
      },
      {
        path: "my-inquiries-res/:id",
        name: "/my-inquiries-res",
        component: () => import("@/views/my-inquiries-res")
      },
      {
        path: "my-quotation-res/:id",
        name: "/my-quotation-res",
        component: () => import("@/views/my-quotation-res")
      },
      {
        path: "samples",
        component: () => import("@/views/samples")
      },
      {
        path: "downloads",
        component: () => import("@/views/downloads")
      },
      {
        path: "exhibitors",
        redirect: "trades",
      },
      {
        path: "storefront",
        component: () => import("@/views/company-list")
      },
      {
        path: "products",
        component: () => import("@/views/product-list")
      },
      {
        path: "/company-exchanged-cards",
        component: () => import("@/views/company-exchanged-cards")
      },
      {
        path: "documents/create",
        component: () => import("@/views/documents/create")
      },
      {
        path: "documents/:id/edit",
        component: () => import("@/views/documents/edit")
      },
      {
        path: "marketing/create",
        component: () => import("@/views/marketing/create")
      },
      {
        path: "marketing/:id/edit",
        component: () => import("@/views/marketing/edit")
      },
      {
        path: "marketing/:id/detail",
        component: () => import("@/views/marketing/detail")
      },

      {
        path: "factories/:id/detail",
        component: () => import("@/views/factories/detail")
      },
      {
        path: "factories/create",
        component: () => import("@/views/factories/create")
      },
      {
        path: "factories/:id/edit",
        component: () => import("@/views/factories/edit")
      },

      {
        path: "invite",
        component: () => import("@/views/company-profile/invite")
      },
      {
        path: "blank",
        component: () => import("@/views/blank")
      },

      // {
      //   path: "products/:id/edit",
      //   component: () => import("@/views/company-profile/products/edit")
      // },

      {
        path: "member/:id",
        component: () => import("@/views/member")
      },

      {
        path: "member/all/assign",
        component: () => import("@/views/member/allassign")
      },

      {
        path: "member/:id/edit",
        component: () => import("@/views/member/edit")
      },

      {
        path: "company/:id",
        name: "company-detail-info",
        component: () => import("@/views/company-info/company-info")
      },

      {
        path: "company/:company_id/associated/:document_id",
        component: () => import("@/views/company/profile-exhibitor/associated")
      },

      {
        path: "company/:id/calendar",
        component: () => import("@/views/company/calendar")
      },
      {
        path: "company/:id/products",
        name: "company-product-list",
        component: () => import("@/views/company-product-list")
      },
      {
        path: "company-profile",
        name: "company-profile",
        component: () => import("@/views/company-profile-info"),
        // redirect: "/company-profile",
        children: [
          {
            path: "detail",
            component: () => import("@/views/company-profile/detail")
          },
          {
            path: "members",
            component: () => import("@/views/company-profile/members")
          },
          {
            path: "conferences",
            component: () => import("@/views/company-profile/conferences")
          },
          {
            path: "documents",
            component: () => import("@/views/company-profile/documents")
          },
          {
            path: "factories",
            component: () => import("@/views/company-profile/factories")
          },
          {
            path: "products",
            component: () => import("@/views/company-profile/products")
          },
          {
            path: "marketing",
            component: () => import("@/views/company-profile/marketing")
          }
        ]
      },

      {
        path: "user-profile",
        component: () => import("@/views/user-profile"),
        redirect: "/user-profile/basic",
        children: [
          {
            path: "basic",
            component: () => import("@/views/user-profile/basic")
          },
          {
            path: "advance",
            component: () => import("@/views/user-profile/advance")
          }
        ]
      },
      {
        path: "/favorites",
        component: () => import("@/views/favorites")
      }
    ]
  },
  {
    path: "/test-test",
    component: LayoutEmpty,
    children: [
      {
        path: "/",
        component: () => import("@/views/test")
      }
    ]
  },
  {
    path: "/chat",
    component: LayoutWithoutSider,
    children: [
      {
        path: "/",
        component: () => import("@/views/chat")
      }
    ]
  },
  {
    path: "/video",
    component: LayoutWithoutSider,
    children: [
      {
        path: "/",
        component: () => import("@/views/video")
      }
    ]
  },
  {
    path: "/company-calendar",
    component: LayoutWithoutSider,
    children: [
      {
        path: "/",
        component: () => import("@/views/company-calendar")
      }
    ]
  },
  {
    path: "/company-meeting",
    component: LayoutWithoutSider,
    children: [
      {
        path: "/",
        component: () => import("@/views/company-meeting")
      }
    ]
  },
  {
    path: "/my-calendar",
    component: LayoutWithoutSider,
    children: [
      {
        path: "/",
        component: () => import("@/views/my-calendar")
      }
    ]
  },
  {
    path: "/products",
    component: Layout,
    children: [
      {
        path: "create",
        component: () => import("@/views/products/create")
      },
      {
        path: ":id",
        name: "product-detail-info",
        component: () => import("@/views/product")
      },
      {
        path: ":id/edit",
        component: () => import("@/views/products/edit")
      }
    ]
  },
  {
    path: "/requests",
    component: Layout,
    children: [
      {
        path: "create",
        component: () => import("@/views/requests/create")
      },
      {
        path: ":id",
        component: () => import("@/views/requests/detail")
      }
    ]
  },
  {
    path: "/user-action",
    component: LayoutBlank,
    children: [
      {
        path: "privacy",
        component: () => import("@/views/user-action/privacy")
      },
      {
        path: "terms",
        component: () => import("@/views/user-action/terms")
      },
      {
        path: "invite",
        component: () => import("@/views/user-action/invite")
      },
      {
        path: "register",
        redirect: "/index?login=true"
      },
      {
        path: "register/attendee",
        component: () => import("@/views/user-action/register-attendee")
      },
      {
        path: "register/exhibitor",
        component: () => import("@/views/user-action/register-exhibitor")
      },
      {
        path: "register-success",
        component: () => import("@/views/user-action/register-success")
      },
      {
        path: "verify",
        component: () => import("@/views/user-action/verify")
      },
      {
        path: "verify-invite",
        component: () => import("@/views/user-action/verify-invite")
      }
    ]
  },
  {
    path: "/user-action",
    component: LayoutEmpty,
    children: [
      {
        path: "login",
        component: () => import("@/views/user-action/login")
      },
      {
        path: "forgot-password",
        component: () => import("@/views/user-action/forgot-password")
      },
      {
        path: "reset-password",
        component: () => import("@/views/user-action/reset-password")
      },
      {
        path: "welcome-invite",
        component: () => import("@/views/user-action/welcome-invite")
      },
      {
        path: "welcome-attendee",
        redirect: "login"
        // component: () => import("@/views/user-action/welcome-attendee")
      },
      {
        path: "welcome-exhibitor",
        redirect: "login"
        // component: () => import("@/views/user-action/welcome-exhibitor")
      }
    ]
  },
  {
    path: "/contact-us",
    component: Layout,
    children: [
      {
        path: "/",
        component: () => import("@/views/contact")
      }
    ]
  },
  {
    path: "/about-us",
    component: Layout,
    children: [
      {
        path: "/",
        component: () => import("@/views/about")
      }
    ]
  },
  {
    path: "/max-sales",
    component: Layout,
    children: [
      {
        path: "/",
        component: () => import("@/views/max-sales")
      }
    ]
  },
  { path: "*", component: NotFound }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

router.afterEach(() => {
  // Remove initial splash screen
  const splashScreen = document.querySelector(".app-splash-screen");
  if (splashScreen) {
    splashScreen.style.opacity = 0;
    setTimeout(
      () => splashScreen && splashScreen.parentNode.removeChild(splashScreen),
      300
    );
  }

  // // On small screens collapse sidenav
  // if (window.layoutHelpers && window.layoutHelpers.isSmallScreen() && !window.layoutHelpers.isCollapsed()) {
  //   setTimeout(() => window.layoutHelpers.setCollapsed(true, true), 10)
  // }

  // // Scroll to top of the page
  // globals().scrollTop(0, 0)
});

router.beforeEach((to, from, next) => {
  // Set loading state
  document.body.classList.add("app-loading");

  // Add tiny timeout to finish page transition
  setTimeout(() => next(), 10);
});

export default router;
