import api from "@/api";
const { _searchSuggestion } = api;

const state = {
  suggestWords: [],
  suggestProducts: []
};

const mutations = {
  common(state, payload) {
    Object.keys(payload).map(key => {
      state[key] = payload[key];
    });
  }
};

const actions = {
  searchSuggestion({ commit }, q) {
    return _searchSuggestion({ q }).then(response => {
      const { data } = response;
      console.log(data);
      commit("common", { suggestWords: data.suggestWords });
      commit("common", { suggestProducts: data.suggestProducts });
    });
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
