<template>
  <div class="ivu-affix ivu-affix-pad">
    <div class="hide-menu" v-if="showMenu">
      <div class="top">
        <div class="logo">
          <img src="@/assets/logo.png" alt="" />
        </div>
        <div class="close">
          <img src="@/assets/close.png" alt="" @click="changeMenu" />
        </div>
      </div>
      <div class="border"></div>
      <div class="hide-menus ">
        <div
          :class="[
            'item',
            'home',
            'font-black',
            { active: ['/index', '/home'].includes($route.path) }
          ]"
          @click="homeWithRedirect()"
        >
          Home
        </div>
        <div
          :class="['item', 'font-black', { active: $route.path == '/maxdata' }]"
          @click="dataSearchWithRedirect()"
        >
          MaxData
        </div>
        <div
          :class="[
            'item',
            'font-black',
            { active: $route.fullPath == '/maxtrade' }
          ]"
          @click="redirect('maxtrade')"
        >
          MaxTrade
        </div>
<!--        <div-->
<!--          :class="[-->
<!--            'item',-->
<!--            'font-black',-->
<!--            { active: $route.fullPath == '/storefront' }-->
<!--          ]"-->
<!--          @click="redirect('storefront')"-->
<!--        >-->
<!--          StoreFronts-->
<!--        </div>-->
      </div>
      <!--      <div-->
      <!--        class="hide-menus "-->
      <!--        v-if="-->
      <!--          selfTopMenu.includes($route.path) || selfTopMenu.includes($route.name)-->
      <!--        "-->
      <!--      >-->
      <!--        <div-->
      <!--          :class="[-->
      <!--            'item',-->
      <!--            'home',-->
      <!--            'font-black',-->
      <!--            { active: $route.path == '/dashboard' }-->
      <!--          ]"-->
      <!--          @click="dashBoardWithRedirect()"-->
      <!--        >-->
      <!--          Dashboard-->
      <!--        </div>-->
      <!--        <div-->
      <!--          :class="[-->
      <!--            'item',-->
      <!--            'font-black',-->
      <!--            { active: ['/my-sourcingso'].includes($route.path) }-->
      <!--          ]"-->
      <!--          @click="redirect('my-sourcingso')"-->
      <!--          v-if="'exhibitor' === role"-->
      <!--        >-->
      <!--          My Products-->
      <!--        </div>-->
      <!--      </div>-->
      <!--      <div class="border"></div>-->
      <!--      <div class="other-menus">-->
      <!--        <div class="message item" @click="redirect('chat')" v-if="role">-->
      <!--          Messages-->
      <!--        </div>-->
      <!--        <div-->
      <!--          class="sell item"-->
      <!--          @click="redirect('sell-on-connexima')"-->
      <!--          v-if="!role"-->
      <!--        >-->
      <!--          Sell on Connexima-->
      <!--        </div>-->
      <!--      </div>-->
    </div>
    <div class="hide-search mobile-search-box" v-if="showSearch">
      <div class="top">
        <div class="logo">
          <img src="@/assets/logo.png" alt="" />
        </div>
        <div class="close">
          <img src="@/assets/close.png" alt="" @click="changeSearch" />
        </div>
      </div>
      <div class="search-input-box">
        <div class="input">
          <input
            ref="searchRef"
            v-on:focus="show = true"
            v-on:keyup.enter="search"
            v-on:input="suggest($event)"
            v-model="q"
            type="text"
            placeholder="Search..."
            :class="{ 'show-type-input': searchType }"
          />
        </div>
        <div class="input-border"><div class="border"></div></div>
        <div class="select-box">
          <div
            :class="['top', 'search-button', { 'show-typ-top': searchType }]"
            @click="changeType"
          >
            <div class="value" v-if="topSearchType === 'all'">All Data</div>
            <div class="value" v-if="topSearchType === 'product'">Products</div>
            <div class="value" v-if="topSearchType === 'company'">
              Storefronts
            </div>
            <div class="img"><img src="@/assets/down.png" /></div>
          </div>
          <!--              <div class="select" v-show="searchType">-->
          <div class="select" v-if="searchType">
            <div @click="chooseType('all')" class="search-button">All Data</div>
            <div @click="chooseType('product')" class="search-button">
              Products
            </div>
            <div @click="chooseType('company')" class="search-button">
              Storefronts
            </div>
          </div>
          <div class="select" v-else></div>
        </div>
      </div>
      <!--      <div class="search-btn search-button" @click="search">-->
      <!--        <img src="@/assets/search.png" alt="" />-->
      <!--      </div>-->
      <div class="data-search">
        <div class="title">
          There are over 112k ingredients on Connexima's marketplace.
        </div>
        <!--        <div class="tips">-->
        <!--          Enter your search in the bar above and start exploring!-->
        <!--        </div>-->
        <div class="pic">
          <img src="@/assets/data-search/data-search-ini.png" />
        </div>
      </div>
      <div class="search-btn" @click="search">Search</div>
    </div>
    <Header class="header " v-if="!showMenu && !showSearch">
      <template v-if="!role">
        <div class="mobile-menu">
          <img
            src="@/assets/logo-w.png"
            alt=""
            class="logo-mini"
            @click="homeWithRedirect()"
          />
        </div>

        <div class="register-and-login">
          <!--          <div class="search">-->
          <!--            <img src="@/assets/search-black.png" alt="" @click="changeSearch" />-->
          <!--          </div>-->

<!--          <div class="register " @click="loginWithRedirect">-->
<!--            Sign In-->
<!--          </div>-->
          <a href="https://calendly.com/jingqiu-uk4/30min" class="request-demo-link" target="_blank">
            <div class="request-demo">
              Request Demo
            </div>
          </a>
          <div class="login " @click="loginWithRedirect">
            Get Started
          </div>
        </div>
      </template>
      <template v-else>
        <div class="mobile-menu">
          <img
            src="@/assets/logo-w.png"
            alt=""
            class="logo-mini"
            @click="homeWithRedirect()"
          />
        </div>

        <div class="notif-user">
          <!--          <div class="search">-->
          <!--            <img src="@/assets/search-black.png" alt="" @click="changeSearch" />-->
          <!--          </div>-->

          <!--          <div class="notif">-->
          <!--            <strong-->
          <!--              class="dian"-->
          <!--              v-if="-->
          <!--                ($store.state.user.notifLists.length &&-->
          <!--                  $store.state.user.notifLists[0].is_read === 0) ||-->
          <!--                  showMessage ||-->
          <!--                  newChatsNum > 0-->
          <!--              "-->
          <!--              >•</strong-->
          <!--            >-->
          <!--            <Icon-->
          <!--              id="notifLetter"-->
          <!--              @click="notifiReadAll"-->
          <!--              type="md-notifications-outline"-->
          <!--            />-->
          <!--            &lt;!&ndash; <span-->
          <!--          id="notifLetter"-->
          <!--          @click="-->
          <!--            $store.state.user.notifShow-->
          <!--              ? $store.commit('user/setNotifShow', false)-->
          <!--              : $store.commit('user/setNotifShow', true),-->
          <!--              (showMessage = false)-->
          <!--          "-->
          <!--          >Notifications</span-->
          <!--        > &ndash;&gt;-->
          <!--            <div class="lists-wrap" v-if="$store.state.user.notifShow">-->
          <!--              <ul-->
          <!--                class="drop-lists"-->
          <!--                v-if="$store.state.user.notifLists.length || newChatsNum > 0"-->
          <!--              >-->
          <!--                <li v-if="newChatsNum > 0">-->
          <!--                  You have a new chat message.-->
          <!--                  <a href="javascript:;" @click="toChatLink">Go</a>-->
          <!--                </li>-->
          <!--                <li v-for="item in $store.state.user.notifLists" :key="item.id">-->
          <!--                  <template v-if="item.type === 1">-->
          <!--                    <strong>{{ item.content[0] }}</strong> from-->
          <!--                    <strong>{{ item.content[1] }}</strong> started a group chat-->
          <!--                    with you-->
          <!--                    <router-link :to="`/chat?sid=${item.param}`"-->
          <!--                      >Join Now</router-link-->
          <!--                    >-->
          <!--                    <p class="time">{{ item.created_at }}</p>-->
          <!--                  </template>-->
          <!--                  <template v-else-if="item.type === 2">-->
          <!--                    <strong>{{ item.content[0] }}</strong> from-->
          <!--                    <strong>{{ item.content[1] }}</strong> has exchanged-->
          <!--                    business card with you-->
          <!--                    <router-link :to="`/chat?company=${item.param}`"-->
          <!--                      >Chat Now</router-link-->
          <!--                    >-->
          <!--                    <p class="time">{{ item.created_at }}</p>-->
          <!--                  </template>-->
          <!--                  &lt;!&ndash; <template v-else-if="item.type === 3">-->
          <!--                    <strong>{{ item.content[0] }}</strong> from-->
          <!--                    <strong>{{ item.content[1] }}</strong> has requested a sample-->
          <!--                    for product {{ item.content[2] }}-->
          <!--                    <router-link :to="`/requests/${item.param}`"-->
          <!--                      >See Details</router-link-->
          <!--                    >-->
          <!--                    <p class="time">{{ item.created_at }}</p>-->
          <!--                  </template> &ndash;&gt;-->
          <!--                  <template v-else-if="item.type === 3">-->
          <!--                    You have received a sample request from-->
          <!--                    <strong>{{ item.content[0] }}</strong-->
          <!--                    >&nbsp;&nbsp;-->
          <!--                    <router-link :to="`/requests/${item.param}`"-->
          <!--                      >View Request</router-link-->
          <!--                    >-->
          <!--                    <p class="time">{{ item.created_at }}</p>-->
          <!--                  </template>-->
          <!--                  <template v-else-if="item.type === 4">-->
          <!--                    <strong>{{ item.content[0] }}</strong> has Rejected your-->
          <!--                    sample request for <strong>{{ item.content[1] }}</strong-->
          <!--                    >&nbsp;&nbsp;-->
          <!--                    <router-link :to="`/requests/${item.param}`"-->
          <!--                      >View Request</router-link-->
          <!--                    >-->
          <!--                    <p class="time">{{ item.created_at }}</p>-->
          <!--                  </template>-->
          <!--                  <template v-else-if="item.type === 5">-->
          <!--                    <strong>{{ item.content[0] }}</strong> has Accepted your-->
          <!--                    sample request for <strong>{{ item.content[1] }}</strong-->
          <!--                    >&nbsp;&nbsp;-->
          <!--                    <router-link :to="`/requests/${item.param}`"-->
          <!--                      >View Request</router-link-->
          <!--                    >-->
          <!--                    <p class="time">{{ item.created_at }}</p>-->
          <!--                  </template>-->
          <!--                  <template v-else-if="item.type === 6">-->
          <!--                    Your sample request for-->
          <!--                    <strong>{{ item.content[1] }}</strong> is on the way. The-->
          <!--                    exhibitor has provided you with a tracking-->
          <!--                    number.&nbsp;&nbsp;-->
          <!--                    <router-link :to="`/requests/${item.param}`"-->
          <!--                      >View Request</router-link-->
          <!--                    >-->
          <!--                    <p class="time">{{ item.created_at }}</p>-->
          <!--                  </template>-->
          <!--                </li>-->
          <!--                <li class="view" v-if="$store.state.user.notifLists.length">-->
          <!--                  <router-link to="/notifications"-->
          <!--                    >View All Notifications</router-link-->
          <!--                  >-->
          <!--                </li>-->
          <!--              </ul>-->
          <!--              <ul class="drop-lists" v-else>-->
          <!--                <li class="view">-->
          <!--                  No Notifications-->
          <!--                </li>-->
          <!--              </ul>-->
          <!--            </div>-->
          <!--          </div>-->
          <!--          <router-link to="/dashboard">-->
          <!--            <div class="name">Hello {{ first_name }}</div>-->
          <!--          </router-link>-->
          <Dropdown
            trigger="click"
            @on-click="clickDropdown"
            class="dropmenu"
            placement="bottom-end"
          >
            <div class="dropdown">
              <VueAvatar :username="full_name" backgroundColor="#fff" color="#FF4835"	v-if="avatar" class="user-avatar-n"></VueAvatar>
              <Avatar v-else></Avatar>
            
            </div>

            <DropdownMenu slot="list">
<!--              <DropdownItem name="dashboard">-->
<!--                Dashboard-->
<!--              </DropdownItem>-->
              <DropdownItem name="user-profile">
                My Profile
              </DropdownItem>
              <DropdownItem name="my-products">
                My Products
              </DropdownItem>

              <DropdownItem name="logout">
                Logout
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </div>
      </template>
    </Header>
    <PopLogin ref="popLogin" @register="register"></PopLogin>
    <PopRegisterSuccess ref="popRegisterSuccess"></PopRegisterSuccess>
    <PopRegister
      ref="popRegister"
      @successRegister="successRegister"
      @login="login"
    ></PopRegister>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Pusher from "pusher-js";
import PopLogin from "@/components/popLogin";
import PopRegisterSuccess from "@/components/popRegisterSuccessMobile";
import PopRegister from "@/components/popRegisterEmail";
// import { MatchMedia } from "vue-component-media-queries";
// import api from "@/api";
// console.log(api);
// const { _searchSuggestion } = api;
import {Avatar as VueAvatar} from 'vue-avatar'

export default {
  components: {
    // MatchMedia
    PopLogin,
    PopRegister,
    PopRegisterSuccess,VueAvatar
  },
  created() {
    const query = this.$route.query;
    this.q = query.q;
  },
  computed: {
    ...mapState("search", ["suggestWords", "suggestProducts"]),
    ...mapState("user", [
      "role",
      "first_name",
      "last_name",
      "avatar",
      "user_id","full_name"
    ]),
    ...mapState("chat", ["channelsSort", "channelsKV"]),
    menus() {
      if (!this.role) {
        return [
          {
            name: "Exhibitors",
            path: "/exhibitors"
          },
          {
            name: "Products",
            path: "/products"
          },
          {
            name: "Sourcing",
            path: "/sourcingso"
          }
        ];
      }
      if (this.role === "exhibitor") {
        return [
          // {
          //   name: "Dashboard",
          //   path: "/home"
          // },
          {
            name: "Company Profile",
            path: "/company-profile"
          },
          {
            name: "Conferences",
            path: "/conferences"
          },
          {
            name: "Sample Requests",
            path: "/samples"
          },
          {
            name: "Sourcing Inquires",
            path: "/sourcings"
          },
          {
            name: "Forum",
            path: "/forum"
          }
        ];
      } else {
        return [
          // {
          //   name: "Dashboard",
          //   path: "/home"
          // },
          {
            name: "Company Profile",
            path: "/company-profile"
          },
          {
            name: "Products",
            path: "/products"
          },
          {
            name: "Exhibitors",
            path: "/exhibitors"
          },
          {
            name: "Conferences",
            path: "/conferences"
          },
          {
            name: "Forum",
            path: "/forum"
          }
        ];
      }
    },
    channels() {
      return this.channelsSort.map(item => this.channelsKV[item]);
    },
    newChatsNum() {
      var num = 0;
      this.channelsSort
        .map(item => this.channelsKV[item])
        .forEach(item => {
          num += item.newMessageCount;
        });
      return num;
    }
  },
  mounted() {
    if(this.user_id){
        this.userFullName = this.first_name +' '+ this.last_name
      }

    var vm = this;
    this.$store.dispatch("user/queryNotification");

    window.document.addEventListener("click", function(e) {
      var el = e || window.event;
      var elem = el.target || el.srcElement;
      while (elem) {
        if (elem.id && elem.id == "notifLetter") {
          return;
        } else {
          vm.$store.commit("user/setNotifShow", false);
        }
        elem = elem.parentNode;
      }
    });

    var pushTag = "63e67aaf1e72172ba5fd";
    var pushClu = "mt1";
    if (process.env.NODE_ENV === "production") {
      pushTag = "a11f07f0f85e1c258ffa";
    }

    // Pusher.logToConsole = true;
    var pusher = new Pusher(pushTag, {
      cluster: pushClu
    });

    const uid = "connexima-notification-channel-" + this.user_id;
    var channel = pusher.subscribe(uid);
    channel.bind("connexima-notification-event", function(data) {
      if (data) vm.showMessage = true;
      vm.$store.dispatch("user/queryNotification");
    });
  },
  data() {
    return {
      showMenu: false,
      drawerVisible: false,
      activeName: this.$route.path,
      show: false,
      q: "",
      showMessage: false,
      live: {
        page: 1,
        limit: 10, // must in 5-20
        total: 0,
        list: [],
        loading: false
      },
      activeNav: "home",
      topMenu: [
        "/index",
        "/data-search",
        "/maxdata",
        "/products",
        "/exhibitors",
        "/sourcing-inquiry",
        "/conferences",
        "/home",
        "company-detail-info",
        "company-product-list",
        "product-detail-info",
        "/maxsource",
        "/maxsell",
        "/sell-on-connexima"
      ],
      selfTopMenu: [
        "/dashboard",
        "/samples",
        "/user-profile/basic",
        "company-profile",
        "/downloads",
        "/chat",
        "/test-chat-and-exchanged",
        "/sourcings",
        "/my-sourcingso",
        "/company-exchanged-cards",
        "sourcing-edit",
        "/notifications",
        "sourcing-quotation-edit"
      ],
      topSearchType: "all",
      searchType: false,
      showSearch: false,
      userFullName: ''
    };
  },
  watch: {
    $route(newRoute) {
      this.activeName = newRoute.path;
      this.q = this.$route.query.q;
      if ("/exhibitors" === this.activeName) {
        this.topSearchType = "company";
      } else if ("/products" === this.activeName) {
        this.topSearchType = "product";
      } else {
        this.topSearchType = "all";
      }
      this.showMenu = false;
    }
  },
  methods: {
    register() {
      this.$refs.popLogin.showLogin = false;
      this.$refs.popRegister.showLogin = true;
    },
    login() {
      this.$refs.popRegister.showLogin = false;
      this.$refs.popLogin.showLogin = true;
    },
    successRegister(token) {
      this.$refs.popRegisterSuccess.showLogin = true;
      this.$refs.popRegisterSuccess.token = token;
    },
    sellWithRedirect() {
      this.$router.push("/sell-on-connexima");
    },
    loginWithRedirect() {
      this.$refs.popLogin.showLogin = true;
    },
    registerWithRedirect() {
      this.$refs.popRegister.showLogin = true;
      return;
    },
    chatWithRedirect() {
      this.$router.push(`/chat?redirect=${this.$route.fullPath}`);
    },
    conferenceWithRedirect() {
      this.$router.push(`/conferences?redirect=${this.$route.fullPath}`);
    },
    goToPage(path) {
      this.$router.push(path);
    },
    goUrl(url) {
      this.$router.push(url);
      this.show = false;
    },
    redirect(url) {
      this.$router.push(`/` + url);
    },
    fos() {
      this.$refs.searchRef.focus();
      this.show = true;
    },
    onClickOutside(e) {
      if (e.target === this.$refs.searchRef) {
        return;
      }
      this.show = false;
    },
    suggest(e) {
      // console.log(e.target.value)
      this.$store.dispatch("search/searchSuggestion", e.target.value);
      // _searchSuggestion(e.target.value).then(response => {
      //   console.log(response);
      // });
    },
    search() {
      if ("product" == this.topSearchType && "/products" === this.$route.path) {
        this.$router.push({
          path: "/blank",
          query: { key: "products", q: this.q }
        });
      } else if ("product" == this.topSearchType) {
        this.$router.push({ path: "/products", query: { q: this.q } });
      } else if (
        "company" == this.topSearchType &&
        "/exhibitors" === this.$route.path
      ) {
        this.$router.push({
          path: "/blank",
          query: { key: "exhibitors", q: this.q }
        });
      } else if (
        "all" == this.topSearchType &&
        "/maxdata" === this.$route.path
      ) {
        this.$router.push({
          path: "/blank",
          query: { key: "maxdata", q: this.q }
        });
      } else if ("all" == this.topSearchType) {
        this.$router.push({ path: "/maxdata", query: { q: this.q } });
      } else {
        this.$router.push({ path: "/exhibitors", query: { q: this.q } });
      }
      this.showSearch = false;
    },
    clickDropdown(name) {
      if (name === "dashboard") {
        this.$router.push("/dashboard");
      }
      if (name === "user-profile") {
        this.$router.push("/user-profile");
      }
      if (name === "my-products") {
        this.$router.push("/my-inquiries");
      }
      if (name === "company-profile") {
        this.$router.push("/company-profile");
      }
      if (name === "message") {
        this.$router.push("/chat");
      }
      if (name === "sourcings") {
        this.$router.push("/sourcings");
      }
      if (name === "downloads") {
        this.$router.push("/downloads");
      }
      if (name === "logout") {
        this.logout();
      }
    },
    notifiReadAll() {
      if (this.newChatsNum === 0) this.showMessage = false;
      this.$store.state.user.notifShow
        ? this.$store.commit("user/setNotifShow", false)
        : this.$store.commit("user/setNotifShow", true);
      this.$store.dispatch("user/notifiReadAll");
      setTimeout(() => {
        this.$store.dispatch("user/queryNotification");
      }, 500);
    },
    toChatLink() {
      // this.showMessage = false;
      const curSid = this.channelsSort[0];
      this.$router.push(`/chat?sid=${curSid}`);
    },
    async logout() {
      const msg = this.$Message.loading({
        content: "Logout...",
        duration: 0
      });

      await this.$store.dispatch("user/logout"); // this action is always resolve , dont need try catch

      msg();

      this.$router.push(`/user-action/login?redirect=${this.$route.fullPath}`);
    },
    homeWithRedirect() {
      this.activeNav = "home";
      this.$router.push(`/index`);
    },
    dashBoardWithRedirect() {
      this.activeNav = "dashboard";
      this.$router.push(`/dashboard`);
    },
    dataSearchWithRedirect() {
      this.activeNav = "search";
      this.$router.push(`/maxdata`);
    },
    productsWithRedirect() {
      this.activeNav = "product";
      this.$router.push(`/products`);
    },
    samplesWithRedirect() {
      this.activeNav = "sample";
      this.$router.push(`/samples`);
    },
    exhibitorsWithRedirect() {
      this.activeNav = "exhibitor";
      this.$router.push(`/exhibitors`);
    },
    changeType() {
      this.searchType = true;
    },
    chooseType(type) {
      this.topSearchType = type;
      this.searchType = false;
    },
    changeMenu() {
      this.showMenu = !this.showMenu;
    },
    changeSearch() {
      this.showSearch = !this.showSearch;
    }
  }
};
</script>

<style lang="less">
.sell {
  margin-right: 10px;
}
.hide-menu {
  width: 100%;
  /*height: 210px;*/
  background: #ffffff;
  .top {
    display: flex;
    justify-content: space-between;
    /*margin-top: 20px;*/
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 10px;
    .logo {
      img {
        width: 130px;
        /*height: 30px;*/
      }
    }
    .close {
      img {
        width: 30px;
        height: 30px;
      }
    }
  }
  .border {
    height: 1px;
    margin-top: 2px;
    margin-bottom: 2px;
    background: #cccccc;
  }
  .hide-menus {
    padding-left: 10px;
    .item {
      font-weight: 600;
      padding-bottom: 7px;
      padding-top: 7px;
    }
    .active {
      color: #FF4835;
      cursor: auto;
    }
  }
  .other-menus {
    padding-left: 10px;
    .item {
      font-weight: 600;
      padding-bottom: 5px;
      padding-top: 5px;
    }
    .active {
      color: #ef842d;
      cursor: auto;
    }
  }
}
/*.navs {*/
/*  background: #ffffff;*/
/*  border-top: 1px solid #cccccc;*/
/*  .content {*/
/*    display: flex;*/
/*    height: 84px;*/
/*    !*width:1500px;*!*/
/*    margin: 0 auto;*/
/*    align-items: center;*/
/*    .nav {*/
/*      margin: 20px;*/
/*      font-size: 22px;*/
/*      font-weight: 700;*/
/*      color: #53575c;*/
/*      line-height: 17px;*/
/*      cursor: pointer;*/
/*    }*/
/*    .home {*/
/*      margin-left: 0px;*/
/*    }*/
/*    .active {*/
/*      color: #ef842d;*/
/*      cursor: auto;*/
/*    }*/
/*  }*/
/*}*/
.ivu-affix-pad {
  top: 0px;
  left: 0px;
  width: 100%;
  position: fixed;
  background: #ffffff;
  z-index: 9999;
  border-bottom: none !important;
}
@media (max-width: 1200px) {
  @max-width: 1100px;
  .search-box {
    .search-input-box {
      input {
        width: 250px;
      }
    }
  }
}

.top-menu {
  height: 40px;
  background: #f2f2f2;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .content {
    /*width: 1500px;*/
    margin-left: auto;
    margin-right: auto;
    div {
      display: inline-block;
      float: right;
      height: 17px;
      font-size: 16px;

      font-weight: 400;
      color: #999999;
      line-height: 17px;
      cursor: pointer;
    }
    .messages {
      /*margin-right:20px;*/
    }
    .conferences {
      margin-right: 20px;
    }
  }
}
.header-menus {
  .menu {
    cursor: pointer;
    font-size: 14px;
    font-weight: bold;
    padding: 14px 12px;
    color: #848484;
    &.active {
      color: @primary-color;
    }
  }
}
.search-button {
  cursor: pointer;
}

.mobile-menu {
  margin-right: 10px;
  margin-left: 5px;
  /*height: 60px;*/
  position: relative;
  top: 10px;
  img {
    width: auto;
    /*height: 33px;*/
  }
}
.logo-mini {
  height: 25px;
}
</style>

<style lang="less" scoped>
.header {
  background: #120D21;
  // box-shadow: 0 2px 8px #f0f1f2;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  @height: 50px;
  // margin-bottom: 10px;
  padding: 0;
  /*width: 1500px;*/
  /*margin: 0 10px;*/
  padding: 0 30px;
  .register-and-login {
    display: flex;
    position: relative;
    align-items: center;
    /*bottom: 10px;*/
    height: 60px;
    .search {
      width: 21px;
      height: 21px;
      img {
        width: 100%;
        height: 100%;
      }
      display: inline-block;
      position: relative;
      top: 7px;
    }
  }
  .login {
    display: inline-block;
    padding: 7px 2px;
    flex-shrink: 0;
    /*margin-right: 20px;*/
    cursor: pointer;
    width: 110px;
    height: 30px;
    background: #ef7f21;
    border-radius: 5px;
    background: #FF4835;
    border-radius: 0;
    font-size: 14px;
    font-family: "Montserrat";
        font-weight: 600;
    color: #fff;
    line-height: 17px;
    text-align: center;
    /*margin-right: 5px;*/
    border-radius: 6px;
  }
  .register {
    display: inline-block;
    padding: 13px 10px;
    color: #fff;
    cursor: pointer;
    flex-shrink: 0;
    line-height: 0;
    font-family: "Montserrat";
        font-weight: 600;
    font-size: 14px;
    margin-right: 5px;
    width: 80px;
    height: 39px;
    /*border: 1px solid #EF7F21;*/
    border-radius: 5px;
    text-align: center;
  }
  .logo {
    height: 48px;
    /*padding: 10px 20px;*/
    /*width:282px;*/
    img {
      /*height: 100%;*/
      display: block;
      width: 140px;
      height: 25px;
    }
  }
  .menus {
    display: flex;
    .menu {
      cursor: pointer;
      font-size: 14px;
      font-weight: bold;
      padding: 14px 12px;
      color: #848484;
      &.active {
        color: @primary-color;
      }
    }
  }
  .search-box {
    display: flex;
    align-items: center;
    border-radius: 50px;
    height: 39px;
    /*width: 300px;*/
    /*flex: 1;*/
    margin-right: 10px;
    .search-input-box {
      border-radius: 20px 0 0 20px;
      /*flex: 1;*/
      height: 100%;
      /*background: #5d5d5d;*/
      line-height: initial;
      /*padding: 0 20px;*/
      input {
        /*width: 550px;*/
        height: 100%;
        outline: none;
        background: transparent;
        /*border: none;*/
        /*color: #fff;*/
        /*caret-color: #fff;*/
        border: 0.5px solid #f3a565;
        border-right: 0.5px solid #cccccc;
        padding-left: 10px;

        &::-webkit-input-placeholder {
          color: #bdcada;
        }
        &:-moz-placeholder {
          color: #bdcada;
        }
        &::-moz-placeholder {
          color: #bdcada;
        }
        &:-ms-input-placeholder {
          color: #bdcada;
        }
        position: relative;
        top: 0px;
        width: 100%;
      }
      .show-type-input {
        position: relative;
        top: -108px;
      }
      .select-box {
        display: inline-block;
        /*width: 150px;*/
        div {
          padding-left: 10px;
        }
        .top {
          /*border: none;*/
          /*height: 39px;*/
          /*padding-top: 9px;*/
          /*background: #f2f2f2;*/

          border-top: 0.5px solid #f3a565;
          border-bottom: 0.5px solid #f3a565;
          height: 39px;
          padding-top: 9px;

          div {
            display: inline-block;
          }
          .value {
            font-size: 18px;

            font-weight: 500;
            color: #53575c;
            line-height: 17px;
          }
          .img {
          }
        }
        .show-typ-top {
          position: relative;
          top: 0px;
        }
        .select {
          position: relative;
          /*top: -4px;*/
          font-size: 18px;

          font-weight: 500;
          color: #53575c;
          line-height: 17px;
          background: #ffffff;
          div {
            height: 35px;
            padding-top: 10px;
          }
          div:hover {
            background-color: #ef7f21;
            color: #ffffff;
          }
        }
      }
      select {
        width: 140px;
        height: 100%;
        /*color: #fff;*/
        /*caret-color: #fff;*/
        border-color: #f3a565;
        border-left: none;

        /*appearance:none;*/
        /*-moz-appearance:none;*/
        /*-webkit-appearance:none;*/

        /*在选择框的最右侧中间显示小箭头图片*/
        /*background: url('../assets/down.png') no-repeat scroll right center transparent;*/
        outline: none;
        font-size: 18px;
        padding: 1px 4px 5px 11px;
      }
      .suggestion {
        min-height: 400px;
        background: #fff;
        position: relative;
        z-index: 999;
        width: 110%;
        border: 1px solid #eee;
        .hd {
          color: #666;
          font-weight: bold;
          font-size: 13px;
          padding-left: 4px;
        }
        .words {
          color: #000;
          padding: 4px 0 4px 20px;
          cursor: pointer;
          &:hover {
            background: #eee;
          }
        }
        .line {
          height: 1px;
          background: #eee;
          width: 100%;
        }
        .product {
          display: flex;
          border-bottom: 1px solid #eee;
          padding: 10px 20px;
          cursor: pointer;
          &:hover {
            background: #eee;
          }
          img {
            width: 100px;
            margin-right: 20px;
          }
          .des {
            display: flex;
            flex-direction: column;
            justify-content: center;
            .title {
              color: #000;
              font-weight: bold;
            }
            .sub-title {
              color: #999;
            }
          }
        }
      }
    }
    .btn {
      border-radius: 0 20px 20px 0;
      background: @primary-color;
      color: #fff;
      padding: 0px 20px;
      display: flex;
      align-items: center;
      justify-self: center;
      line-height: initial;
      height: 100%;
      cursor: pointer;
    }
    .search-btn {
      width: 81px;
      height: 39px;
      background: #ef7f21;
      border-radius: 0px 5px 5px 0px;
      img {
        width: 24px;
        height: 24px;
        position: absolute;
        margin-top: 8px;
        margin-left: 28px;
      }
    }
  }
  .notif-user {
    display: flex;
    /*margin-left:125px;*/
    margin-right: 5px;
    .search {
      width: 21px;
      height: 21px;
      margin-right: 10px;
      img {
        width: 100%;
        height: 100%;
      }
      display: inline-block;
      position: relative;
      /*top: 7px;*/
    }
  }

  .notif {
    position: relative;
    // position: absolute;
    // top: 20px;
    // right: 200px;
    margin-left: auto;
    /*height: 26px;*/
    line-height: 25px;
    font-size: 13px;
    padding: 0 5px;
    /*border-radius: 5px;*/
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    color: @primary-color;
    /*margin-top:13px;*/
    span {
      cursor: pointer;
    }
    strong.dian {
      position: absolute;
      top: -8px;
      left: 15px;
      color: #ef2621;
      font-size: 41px;
    }
    .ivu-icon {
      font-size: 24px;
      cursor: pointer;
      background-image: url("../assets/bell.png");
    }
    .lists-wrap {
      position: absolute;
      top: 24px;
      right: -2px;
      padding-top: 17px;
      width: 230px;
    }
    .drop-lists {
      width: 230px;
      background-color: #4a4a4a;
      list-style: none;
      overflow: hidden;
      padding: 5px 0;
      box-sizing: border-box;
      border-radius: 4px;
      box-shadow: 0 1px 6px rgba(0, 0, 0, 0.2);
      position: absolute;
      z-index: 900;
      li {
        word-break: break-word;
        color: #d2d2d2;
        font-size: 14px;
        margin: 0;
        line-height: normal;
        padding: 7px 16px 10px;
        clear: both;
        list-style: none;
        transition: background 0.2s ease-in-out;
        border-bottom: 1px solid #3a3a3a;
        strong {
          color: #f8f8f8;
        }
        .time {
          padding: 6px 0 4px;
        }
      }
      li.view {
        border: none;
        text-align: center;
        color: @primary-color;
        padding-bottom: 5px;
        cursor: pointer;
      }
      a {
        font-weight: bold;
        color: @primary-color;
        border-bottom: 1px solid @primary-color;
      }
    }
  }
  .name {
    margin: 0 10px;
    font-size: 18px;

    font-weight: 500;
    color: #53575c;
    line-height: 18px;
    padding-top: 17px;
  }
  .dropmenu {
    .dropdown {
      display: flex;
      align-items: center;
      /*font-size: 20px !important;*/
      height: 50px;
      /*padding-left:20px;*/
      cursor: pointer;
      color: #ef7f21;

      .icon {
        /*margin: 0 10px;*/
        /*color: rgb(242, 242, 242);*/
        font-size: 14px;
      }
    }
    /deep/ .ivu-select-dropdown {
      background-color: #4a4a4a;
      .ivu-dropdown-item {
        color: #d2d2d2;
        cursor: pointer;
        &:hover {
          background-color: #777;
        }
      }
    }
  }
}
.mobile-search-box {
  /*display: flex;*/
  align-items: center;
  border-radius: 50px;
  height: 550px;
  /*width: 300px;*/
  /*flex: 1;*/
  /*margin-right: 10px;*/

  .search-input-box {
    border-radius: 20px 0 0 20px;
    /*flex: 1;*/
    height: 39px;
    /*background: #5d5d5d;*/
    line-height: initial;
    /*padding-left: 10px;*/
    display: flex;
    justify-content: center;
    /*padding: 0 20px;*/
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 20px;
    .input {
      /*position: relative;*/
      /*top: 19px;*/
    }
    input {
      height: 100%;
      outline: none;
      background: #f2f2f2;
      /* border: none; */
      /* color: #fff; */
      /* caret-color: #fff; */
      border: none;
      /* border-right: 0.5px solid #CCCCCC; */
      padding-left: 10px;
      position: relative;
      top: 0px;
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
      width: 100%;
    }

    .show-type-input {
      position: relative;
      /*top: -108px;*/
    }

    .select-box {
      display: inline-block;
      /*width: 150px;*/

      div {
        padding-left: 10px;
      }

      .top {
        height: 39px;
        padding-top: 9px;
        border: none;
        padding-top: 9px;
        background: #f2f2f2;
        width: 130px;
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
        margin-top: 0px;
        div {
          display: inline-block;
        }

        .value {
          font-size: 15px;

          font-weight: 500;
          color: #53575c;
          line-height: 17px;
        }

        .img {
        }
      }

      .show-typ-top {
        position: relative;
        top: 0px;
      }

      .select {
        position: relative;
        /*top: -4px;*/
        font-size: 18px;

        font-weight: 500;
        color: #53575c;
        line-height: 17px;
        background: #ffffff;

        div {
          height: 35px;
          padding-top: 10px;
        }

        div:hover {
          background-color: #ef7f21;
          color: #ffffff;
        }
      }
    }

    select {
      width: 140px;
      height: 100%;
      /*color: #fff;*/
      /*caret-color: #fff;*/
      border-color: #f3a565;
      border-left: none;

      /*appearance:none;*/
      /*-moz-appearance:none;*/
      /*-webkit-appearance:none;*/

      /*在选择框的最右侧中间显示小箭头图片*/
      /*background: url('../assets/down.png') no-repeat scroll right center transparent;*/
      outline: none;
      font-size: 18px;
      padding: 1px 4px 5px 11px;
    }

    .suggestion {
      min-height: 400px;
      background: #fff;
      position: relative;
      z-index: 999;
      width: 110%;
      border: 1px solid #eee;

      .hd {
        color: #666;
        font-weight: bold;
        font-size: 13px;
        padding-left: 4px;
      }

      .words {
        color: #000;
        padding: 4px 0 4px 20px;
        cursor: pointer;

        &:hover {
          background: #eee;
        }
      }

      .line {
        height: 1px;
        background: #eee;
        width: 100%;
      }

      .product {
        display: flex;
        border-bottom: 1px solid #eee;
        padding: 10px 20px;
        cursor: pointer;

        &:hover {
          background: #eee;
        }

        img {
          width: 100px;
          margin-right: 20px;
        }

        .des {
          display: flex;
          flex-direction: column;
          justify-content: center;

          .title {
            color: #000;
            font-weight: bold;
          }

          .sub-title {
            color: #999;
          }
        }
      }
    }
  }

  .btn {
    border-radius: 0 20px 20px 0;
    background: @primary-color;
    color: #fff;
    padding: 0px 20px;
    display: flex;
    align-items: center;
    justify-self: center;
    line-height: initial;
    height: 100%;
    cursor: pointer;
  }

  .search-btn {
    width: 150px;
    height: 39px;
    background: #ef7f21;
    border-radius: 5px;
    font-size: 22px;
    color: #ffffff;
    font-weight: 500;
    padding-top: 4px;
    margin: 20px auto 0 auto;
    text-align: center;
    img {
      width: 24px;
      height: 24px;
      position: absolute;
      margin-top: 8px;
      margin-left: 28px;
    }
  }
  .top {
    display: flex;
    justify-content: space-between;
    /*margin-top: 19px;*/
    padding-left: 10px;
    padding-right: 10px;
    margin-top: 10px;
    padding-top: 20px;
    .logo {
      img {
        width: 175px;
        height: 30px;
      }
    }
    .close {
      img {
        width: 30px;
        height: 30px;
      }
    }
  }
  .data-search {
    text-align: center;
    margin-top: 30px;
    img {
      margin-top: 30px;
      width: 280px;
      height: 200px;
    }
    .title {
      /*width: 660px;*/
      /*height: 70px;*/
      font-size: 22px;
      font-weight: 500;
      color: #252729;
      line-height: 30px;
      margin: 30px auto 0 auto;
      padding-left: 10px;
      padding-right: 10px;
    }
    .tips {
      /*height: 200px;*/
      font-size: 21px;
      font-weight: 500;
      color: #999999;
      line-height: 38px;
      margin: 15px auto 0 auto;
      padding-left: 10px;
      padding-right: 10px;
    }
  }
}
.input-border {
  display: flex;
  align-items: center;
  background: #f2f2f2;
  .border {
    width: 0px;
    height: 16px;
    border: 1px solid #e0e0e0;
    /*transform: rotate(90deg);*/
  }
}
.user-avatar-n{
    width: 32px !important;
    height: 32px !important;
    font-size: 12px !important;
    span{
      font-size: 12px !important;
    }
  }
.request-demo-link{
  .request-demo{
    padding: 3px 12px;
    border: 1px solid #fff;
    height: 30px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 15px;
    font-weight: 600;
    font-size: 14px;
    border-radius: 6px;
    &:hover{
      background: #FF4835;
      border: 1px solid #FF4835;
      transition: all 0.2s linear;
    }
  }
}
</style>
