<template>
  <div>
    <div class="error-tips" v-if="isError">
      We have send a verification code to this email. Please enter the code you
      received below:
    </div>
    <div class="six-input-box">
      <input
        v-for="(item, index) in inputbox"
        :key="index"
        :ref="`ref${index}`"
        :class="['input', { 'input-0': index === 0 }]"
        v-model="item.value"
        type="text"
        oninput="value=value.replace(/[^\d]/g,'')"
        @input="onInput(index)"
        @keyup.delete="onDelete(index)"
        maxlength="1"
      />
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      inputbox: [
        {
          value: ""
        },
        {
          value: ""
        },
        {
          value: ""
        },
        {
          value: ""
        },
        {
          value: ""
        },
        {
          value: ""
        }
      ],
      isError: true
    };
  },
  methods: {
    onInput(index) {
      // index < 5 ，如果是第6格，不触发光标移动至下一个输入框。
      if (this.inputbox[index].value && index < 5) {
        this.$refs["ref" + (index + 1)][0].focus();
      }
      var inputValue = "";
      this.inputbox.forEach(item => {
        inputValue = inputValue + item.value;
      });
      if (inputValue.length < 6) {
        this.isError = true;
      } else {
        this.isError = false;
      }
    },
    onDelete(index) {
      // 如果是第1格，不触发光标移动至上一个输入框
      if (index > 0) {
        this.$refs["ref" + (index - 1)][0].focus();
      }
    }
  }
};
</script>
<style scoped>
.six-input-box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.input {
  display: flex;
  width: 70px;
  margin-left: 10px;
  height: 44px;
  font-size: 18px;
  color: #000;
  /*background-color: #f2f2f2;*/
  text-align: center;
  outline: none;
  border: 1px solid #cbc8c8;
  border-radius: 8px;
}
.input-0 {
  margin-left: 0;
}
.error-tips {
  /*margin-left: 10px;*/
  color: #666;
  font-size: 14px;
  max-width: 440px;
  margin-top: 10px;
  margin-bottom: 10px;
}

@media (max-width: 800px) {
  .input {
    width: 40px;
  }
}
</style>
