<template>
  <div class="ivu-affix">
    <Header class="header sc-width">
      <template v-if="!role">
        <div class="search-box">
          <router-link to="/index" class="logo">
            <img src="@/assets/logo-w.png" alt="" />
          </router-link>
        </div>
        <div class="menu">
          <div class="navs ">
            <div class="content">
              <div
                :class="[
                  'nav',
                  'home',
                  'font-black',
                  { active: ['/index', '/home'].includes($route.path) }
                ]"
                @click="homeWithRedirect()"
              >
                Home
              </div>
              <div
                :class="[
                  'nav',
                  'font-black',
                  { active: $route.path == '/maxdata' }
                ]"
                @click="dataSearchWithRedirect()"
              >
                MaxData
              </div>
              <div
                :class="[
                  'nav',
                  'font-black',
                  { active: $route.fullPath == '/maxtrade' }
                ]"
                @click="redirect('maxtrade')"
              >
                MaxTrade
              </div>
              <div
                :class="[
                      'nav',
                      'font-black',
                      { active: $route.fullPath == '/max-sales' }
                    ]"
                @click="redirect('max-sales')"
              >
                MaxSales
              </div>
<!--              <div-->
<!--                :class="[-->
<!--                      'nav',-->
<!--                      'font-black',-->
<!--                      { active: $route.fullPath == '/storefront' }-->
<!--                    ]"-->
<!--                @click="redirect('storefront')"-->
<!--              >-->
<!--                StoreFront-->
<!--              </div>-->

            </div>
          </div>
        </div>

        <div class="register-and-login">
          <div
            class="login  search-button"
            @click="loginWithRedirect"
          >
            Sign In
          </div>
          <a href="https://calendly.com/jingqiu-uk4/30min" class="request-demo-link" target="_blank">
            <div class="request-demo">
              Request Demo
            </div>
          </a>

          <div class="register " @click="registerWithRedirect">
            Free Sign Up
          </div>
        </div>
      </template>
      <template v-else>
        <MatchMedia query="(max-width: 840px)" v-slot="{ matches }">
          <div v-if="matches" style="margin-left: 20px;">
            <Button @click="drawerVisible = true" type="primary">
              <Icon type="ios-menu" />
            </Button>
          </div>
          <fragment v-else>
            <div class="search-box">
              <router-link to="/index" class="logo">
                <img src="@/assets/logo-w.png" alt="" />
              </router-link>
            </div>
            <div class="menu">
              <div class="navs ">
                <div class="content">
                  <div
                    :class="[
                      'nav',
                      'home',
                      'font-black',
                      { active: ['/index', '/home'].includes($route.path) }
                    ]"
                    @click="homeWithRedirect()"
                  >
                    Home
                  </div>
                  <div
                    :class="[
                      'nav',
                      'font-black',
                      { active: $route.path == '/maxdata' }
                    ]"
                    @click="dataSearchWithRedirect()"
                  >
                    MaxData
                  </div>
                  <div
                    :class="[
                      'nav',
                      'font-black',
                      { active: $route.fullPath == '/maxtrade' }
                    ]"
                    @click="redirect('maxtrade')"
                  >
                    MaxTrade
                  </div>
                  <div
                    :class="[
                      'nav',
                      'font-black',
                      { active: $route.fullPath == '/max-sales' }
                    ]"
                    @click="redirect('max-sales')"
                  >
                    MaxSales
                  </div>
<!--                  <div-->
<!--                    :class="[-->
<!--                      'nav',-->
<!--                      'font-black',-->
<!--                      { active: $route.fullPath == '/storefront' }-->
<!--                    ]"-->
<!--                    @click="redirect('storefront')"-->
<!--                  >-->
<!--                    StoreFront-->
<!--                  </div>-->
                </div>
              </div>
            </div>
          </fragment>
        </MatchMedia>
        <div class="notif-user">
<!--          <div class="sell font-black search-button"></div>-->
<!--          <div class="notif">-->
<!--            <strong-->
<!--              class="dian search-button"-->
<!--              id="notifLetter"-->
<!--              @click="notifiReadAll"-->
<!--              v-if="-->
<!--                          ($store.state.user.notifLists.length &&-->
<!--                            $store.state.user.notifLists[0].is_read === 0) ||-->
<!--                            showMessage ||-->
<!--                            newChatsNum > 0-->
<!--                        "-->
<!--            >•</strong-->
<!--            >-->
<!--            <Icon-->
<!--              id="notifLetter"-->
<!--              @click="notifiReadAll"-->
<!--              type="md-notifications-outline"-->
<!--            />-->
<!--            &lt;!&ndash; <span-->
<!--          id="notifLetter"-->
<!--          @click="-->
<!--            $store.state.user.notifShow-->
<!--              ? $store.commit('user/setNotifShow', false)-->
<!--              : $store.commit('user/setNotifShow', true),-->
<!--              (showMessage = false)-->
<!--          "-->
<!--          >Notifications</span-->
<!--        > &ndash;&gt;-->
<!--            <div class="lists-wrap" v-if="$store.state.user.notifShow">-->
<!--              <ul-->
<!--                class="drop-lists"-->
<!--                v-if="$store.state.user.notifLists.length || newChatsNum > 0"-->
<!--              >-->
<!--                <li v-if="newChatsNum > 0">-->
<!--                  You have a new chat message.-->
<!--                  <a href="javascript:;" @click="toChatLink">Go</a>-->
<!--                </li>-->
<!--                <li v-for="item in $store.state.user.notifLists" :key="item.id">-->
<!--                  <template v-if="item.type === 1">-->
<!--                    <strong>{{ item.content[0] }}</strong> from-->
<!--                    <strong>{{ item.content[1] }}</strong> started a group chat-->
<!--                    with you-->
<!--                    <router-link :to="`/chat?sid=${item.param}`"-->
<!--                    >Join Now</router-link-->
<!--                    >-->
<!--                    <p class="time">{{ item.created_at }}</p>-->
<!--                  </template>-->
<!--                  <template v-else-if="item.type === 2">-->
<!--                    <strong>{{ item.content[0] }}</strong> from-->
<!--                    <strong>{{ item.content[1] }}</strong> has exchanged-->
<!--                    business card with you-->
<!--                    <router-link :to="`/chat?company=${item.param}`"-->
<!--                    >Chat Now</router-link-->
<!--                    >-->
<!--                    <p class="time">{{ item.created_at }}</p>-->
<!--                  </template>-->
<!--                  &lt;!&ndash; <template v-else-if="item.type === 3">-->
<!--                    <strong>{{ item.content[0] }}</strong> from-->
<!--                    <strong>{{ item.content[1] }}</strong> has requested a sample-->
<!--                    for product {{ item.content[2] }}-->
<!--                    <router-link :to="`/requests/${item.param}`"-->
<!--                      >See Details</router-link-->
<!--                    >-->
<!--                    <p class="time">{{ item.created_at }}</p>-->
<!--                  </template> &ndash;&gt;-->
<!--                  <template v-else-if="item.type === 3">-->
<!--                    You have received a sample request from-->
<!--                    <strong>{{ item.content[0] }}</strong-->
<!--                    >&nbsp;&nbsp;-->
<!--                    <router-link :to="`/requests/${item.param}`"-->
<!--                    >View Request</router-link-->
<!--                    >-->
<!--                    <p class="time">{{ item.created_at }}</p>-->
<!--                  </template>-->
<!--                  <template v-else-if="item.type === 4">-->
<!--                    <strong>{{ item.content[0] }}</strong> has Rejected your-->
<!--                    sample request for <strong>{{ item.content[1] }}</strong-->
<!--                  >&nbsp;&nbsp;-->
<!--                    <router-link :to="`/requests/${item.param}`"-->
<!--                    >View Request</router-link-->
<!--                    >-->
<!--                    <p class="time">{{ item.created_at }}</p>-->
<!--                  </template>-->
<!--                  <template v-else-if="item.type === 5">-->
<!--                    <strong>{{ item.content[0] }}</strong> has Accepted your-->
<!--                    sample request for <strong>{{ item.content[1] }}</strong-->
<!--                  >&nbsp;&nbsp;-->
<!--                    <router-link :to="`/requests/${item.param}`"-->
<!--                    >View Request</router-link-->
<!--                    >-->
<!--                    <p class="time">{{ item.created_at }}</p>-->
<!--                  </template>-->
<!--                  <template v-else-if="item.type === 6">-->
<!--                    Your sample request for-->
<!--                    <strong>{{ item.content[1] }}</strong> is on the way. The-->
<!--                    exhibitor has provided you with a tracking-->
<!--                    number.&nbsp;&nbsp;-->
<!--                    <router-link :to="`/requests/${item.param}`"-->
<!--                    >View Request</router-link-->
<!--                    >-->
<!--                    <p class="time">{{ item.created_at }}</p>-->
<!--                  </template>-->
<!--                </li>-->
<!--                <li class="view" v-if="$store.state.user.notifLists.length">-->
<!--                  <router-link to="/notifications"-->
<!--                  >View All Notifications</router-link-->
<!--                  >-->
<!--                </li>-->
<!--              </ul>-->
<!--              <ul class="drop-lists" v-else>-->
<!--                <li class="view">-->
<!--                  No Notifications-->
<!--                </li>-->
<!--              </ul>-->
<!--            </div>-->
<!--          </div>-->
          <Dropdown
            trigger="click"
            @on-click="clickDropdown"
            class="dropmenu"
            placement="bottom-end"
          >
            <div class="dropdown" @click="showMenusMethod">
              <VueAvatar :username="full_name" backgroundColor="#fff" color="#FF4835"	v-if="avatar && avatar.indexOf(defaultAvatarUrl) > -1"  class="user-avatar-n" ></VueAvatar>
              <Avatar v-else></Avatar>
              <Icon
                class="icon"
                type="md-arrow-dropdown"
                style="font-size: 20px"
                color="#fff"
                v-if="!showMenus"
              />
              <Icon
                class="icon"
                type="md-arrow-dropup"
                style="font-size: 20px"
                color="#fff"
                v-else
              />
            </div>
            <div class="dropdown-menu" v-if="showMenus">
              <div class="logo">
                <VueAvatar :username="full_name" backgroundColor="#eee" color="#FF4835"	v-if="avatar && avatar.indexOf(defaultAvatarUrl) > -1"  class="user-avatar-n-down" ></VueAvatar>
                <Avatar v-else></Avatar>
              </div>
              <div class="name">{{full_name}}</div>
              <div class="email">{{email}}</div>
              <div class="line"></div>
                <div class="item-name" @click="jumpTo('/user-profile')">
                  My Profile
                </div>
              <div class="line"></div>
                <div class="item-name" @click="jumpTo('/my-inquiries')">
                  My Products
                </div>
              <div class="line"></div>
<!--              <router-link to="/company-profile" >-->
<!--                <div class="item-name" @click="jumpTo('/company-profile')">-->
<!--                  Company Profile-->
<!--                </div>-->
<!--              </router-link>-->
<!--              <div class="line"></div>-->
              <div class="logout" @click="logout">
                Logout
              </div>
            </div>
          </Dropdown>
        </div>
      </template>
    </Header>
    <PopLogin ref="popLogin" @register="register"></PopLogin>
    <PopRegister
      ref="popRegister"
      @successRegister="successRegister"
      @login="login"
    ></PopRegister>
    <PopRegisterEmail
      ref="popRegisterEmail"
      @successRegister="successRegister"
      @login="login"
    ></PopRegisterEmail>
    <PopRegisterSuccess ref="popRegisterSuccess"></PopRegisterSuccess>
  </div>
</template>

<script>
  import { mapState } from "vuex";
  import Pusher from "pusher-js";
  import { MatchMedia } from "vue-component-media-queries";
  import PopLogin from "@/components/popLogin";
  import PopRegister from "@/components/popRegister";
  import PopRegisterEmail from "@/components/popRegisterEmail";
  import PopRegisterSuccess from "@/components/popRegisterSuccess";
  import {Avatar as VueAvatar} from 'vue-avatar'

  // import api from "@/api";
  // console.log(api);
  // const { _searchSuggestion } = api;

  export default {
    components: {
      MatchMedia,
      PopLogin,
      PopRegister,
      PopRegisterSuccess,
      PopRegisterEmail,VueAvatar
    },
    created() {
      const query = this.$route.query;
      this.q = query.q;
    },
    computed: {
      ...mapState("search", ["suggestWords", "suggestProducts"]),
      ...mapState("user", [
        "role",
        "first_name",
        "last_name",
        "avatar",
        "user_id","full_name","email"
      ]),
      ...mapState("chat", ["channelsSort", "channelsKV"]),
      menus() {
        if (!this.role) {
          return [
            {
              name: "Exhibitors",
              path: "/exhibitors"
            },
            {
              name: "Products",
              path: "/products"
            },
            {
              name: "Sourcing",
              path: "/sourcingso"
            }
          ];
        }
        if (this.role === "exhibitor") {
          return [
            {
              name: "Dashboard",
              path: "/home"
            },
            {
              name: "Company Profile",
              path: "/company-profile"
            },
            {
              name: "Conferences",
              path: "/conferences"
            },
            {
              name: "Sample Requests",
              path: "/samples"
            },
            {
              name: "Sourcing Inquires",
              path: "/sourcings"
            },
            {
              name: "Forum",
              path: "/forum"
            }
          ];
        } else {
          return [
            {
              name: "Dashboard",
              path: "/home"
            },
            {
              name: "Company Profile",
              path: "/company-profile"
            },
            {
              name: "Products",
              path: "/products"
            },
            {
              name: "Exhibitors",
              path: "/exhibitors"
            },
            {
              name: "Conferences",
              path: "/conferences"
            },
            {
              name: "Forum",
              path: "/forum"
            }
          ];
        }
      },
      channels() {
        return this.channelsSort.map(item => this.channelsKV[item]);
      },
      newChatsNum() {
        var num = 0;
        this.channelsSort
          .map(item => this.channelsKV[item])
          .forEach(item => {
            num += item.newMessageCount;
          });
        return num;
      }
    },
    mounted() {
      if(this.user_id){
        this.userFullName = this.first_name +' '+ this.last_name
      }

      var vm = this;
      this.$store.dispatch("user/queryNotification");

      window.document.addEventListener("click", function(e) {
        var el = e || window.event;
        var elem = el.target || el.srcElement;
        while (elem) {
          if (elem.id && elem.id == "notifLetter") {
            return;
          } else {
            vm.$store.commit("user/setNotifShow", false);
          }
          elem = elem.parentNode;
        }
      });

      var pushTag = "63e67aaf1e72172ba5fd";
      var pushClu = "mt1";
      if (process.env.NODE_ENV === "production") {
        pushTag = "a11f07f0f85e1c258ffa";
      }

      // Pusher.logToConsole = true;
      var pusher = new Pusher(pushTag, {
        cluster: pushClu
      });

      const uid = "connexima-notification-channel-" + this.user_id;
      var channel = pusher.subscribe(uid);
      channel.bind("connexima-notification-event", function(data) {
        if (data) vm.showMessage = true;
        vm.$store.dispatch("user/queryNotification");
      });
    },
    data() {
      return {
        mobileWidth: window.innerWidth < 800,
        drawerVisible: false,
        activeName: this.$route.path,
        show: false,
        q: "",
        showMessage: false,
        live: {
          page: 1,
          limit: 10, // must in 5-20
          total: 0,
          list: [],
          loading: false
        },
        activeNav: "home",
        topMenu: [
          "/index",
          "/data-search",
          "/maxdata",
          "/products",
          "/exhibitors",
          "/sourcing-inquiry",
          "/conferences",
          "/home",
          "company-detail-info",
          "company-product-list",
          "product-detail-info",
          "/trades",
          "/maxsell",
          "/sell-on-connexima",
          "/contact-us",
          "/about-us",
          "/maxtrade",
          "/buy",
          "/sell"
        ],
        selfTopMenu: [
          "/dashboard",
          "/samples",
          "/user-profile/basic",
          "company-profile",
          "/downloads",
          "/chat",
          "/test-chat-and-exchanged",
          "/sourcings",
          "/my-quotations",
          "/my-inquiries",
          "/my-inquiries-res",
          "/my-quotation-res",
          "/company-exchanged-cards",
          "sourcing-edit",
          "/notifications",
          "sourcing-quotation-edit",
          "/favorites"
        ],
        topSearchType: "all",
        searchType: false,
        showPopLogin: false,
        userFullName: '',
        defaultAvatarUrl: 'uBtmZu63VQQVDfPJDaom94',
        showMenus: false
      };
    },
    watch: {
      $route(newRoute) {
        this.activeName = newRoute.path;
        this.q = this.$route.query.q;
        if ("/exhibitors" === this.activeName) {
          this.topSearchType = "company";
        } else if ("/products" === this.activeName) {
          this.topSearchType = "product";
        } else {
          this.topSearchType = "all";
        }
      }
    },
    methods: {
      jumpTo(path){
        this.$router.push(path);
        this.showMenus = false;
      },
      showMenusMethod(){
        this.showMenus = !this.showMenus;
      },
      register() {
        this.$refs.popLogin.showLogin = false;
        this.$refs.popRegisterEmail.showLogin = true;
      },
      login() {
        this.$refs.popRegisterEmail.showLogin = false;
        this.$refs.popLogin.showLogin = true;
      },
      successRegister(token) {
        this.$refs.popRegisterSuccess.showLogin = true;
        this.$refs.popRegisterSuccess.token = token;
      },
      sellWithRedirect() {
        this.$router.push("/sell-on-connexima");
      },
      loginWithRedirect() {
        this.$refs.popLogin.showLogin = true;
        return;
      },
      registerWithRedirect() {
        this.$refs.popRegisterEmail.showLogin = true;
        return;
      },
      chatWithRedirect() {
        this.$router.push(`/chat?redirect=${this.$route.fullPath}`);
      },
      conferenceWithRedirect() {
        this.$router.push(`/conferences?redirect=${this.$route.fullPath}`);
      },
      goToPage(path) {
        this.$router.push(path);
      },
      goUrl(url) {
        this.$router.push(url);
        this.show = false;
      },
      redirect(url) {
        this.$router.push(`/` + url);
      },
      fos() {
        this.$refs.searchRef.focus();
        this.show = true;
      },
      onClickOutside(e) {
        if (e.target === this.$refs.searchRef) {
          return;
        }
        this.show = false;
      },
      suggest(e) {
        // console.log(e.target.value)
        this.$store.dispatch("search/searchSuggestion", e.target.value);
        // _searchSuggestion(e.target.value).then(response => {
        //   console.log(response);
        // });
      },
      search() {
        if ("product" == this.topSearchType && "/products" === this.$route.path) {
          this.$router.push({
            path: "/blank",
            query: { key: "products", q: this.q }
          });
        } else if ("product" == this.topSearchType) {
          this.$router.push({ path: "/products", query: { q: this.q } });
        } else if (
          "company" == this.topSearchType &&
          "/exhibitors" === this.$route.path
        ) {
          this.$router.push({
            path: "/blank",
            query: { key: "exhibitors", q: this.q }
          });
        } else if (
          "all" == this.topSearchType &&
          "/maxdata" === this.$route.path
        ) {
          this.$router.push({
            path: "/blank",
            query: { key: "data-search", q: this.q }
          });
        } else if ("all" == this.topSearchType) {
          this.$router.push({ path: "/maxdata", query: { q: this.q } });
        } else {
          this.$router.push({ path: "/exhibitors", query: { q: this.q } });
        }
      },
      clickDropdown(name) {
        if (name === "my-calendar") {
          this.$router.push("/my-calendar");
        }
        if (name === "user-profile") {
          this.$router.push("/user-profile");
        }
        if (name === "my-products") {
          this.$router.push("/my-inquiries");
        }
        if (name === "dashboard") {
          this.$router.push("/dashboard");
        }
        if (name === "company-profile") {
          this.$router.push("/company-profile");
        }
        if (name === "my-favorites") {
          this.$router.push("/favorites");
        }
        if (name === "message") {
          this.$router.push("/chat");
        }
        if (name === "sourcings") {
          this.$router.push("/sourcings");
        }
        if (name === "downloads") {
          this.$router.push("/downloads");
        }
        // if (name === "storefront") {
        //   this.$router.push("/company-profile");
        // }
        if (name === "logout") {
          this.logout();
        }
      },
      notifiReadAll() {
        if (this.newChatsNum === 0) this.showMessage = false;
        this.$store.state.user.notifShow
          ? this.$store.commit("user/setNotifShow", false)
          : this.$store.commit("user/setNotifShow", true);
        this.$store.dispatch("user/notifiReadAll");
        setTimeout(() => {
          this.$store.dispatch("user/queryNotification");
        }, 500);
      },
      toChatLink() {
        // this.showMessage = false;
        const curSid = this.channelsSort[0];
        this.$router.push(`/chat?sid=${curSid}`);
      },
      async logout() {
        const msg = this.$Message.loading({
          content: "Logout...",
          duration: 0
        });

        await this.$store.dispatch("user/logout"); // this action is always resolve , dont need try catch

        msg();
        this.$router.go(0);
        // this.$router.push(`/user-action/login?redirect=${this.$route.fullPath}`);
      },
      homeWithRedirect() {
        this.activeNav = "home";
        this.$router.push(`/index`);
      },
      dashBoardWithRedirect() {
        this.activeNav = "dashboard";
        this.$router.push(`/dashboard`);
      },
      dataSearchWithRedirect() {
        this.activeNav = "search";
        this.$router.push(`/maxdata`);
      },
      productsWithRedirect() {
        this.activeNav = "product";
        this.$router.push(`/products`);
      },
      samplesWithRedirect() {
        this.activeNav = "sample";
        this.$router.push(`/samples`);
      },
      exhibitorsWithRedirect() {
        this.activeNav = "exhibitor";
        this.$router.push(`/exhibitors`);
      },
      changeType() {
        this.searchType = true;
      },
      chooseType(type) {
        this.topSearchType = type;
        this.searchType = false;
      }
    }
  };
</script>

<style lang="less">
  .navs {
    background: #120D21;
    border-top: none;
    .content {
      display: flex;
      height: 55px;
      /*width:1500px;*/
      margin: 0 auto;
      align-items: center;
      .nav {
        margin: 0 60px 0 40px;
        font-size: 18px;
        font-weight: 700;
        color: #fff;
        line-height: 17px;
        cursor: pointer;
        &:hover{
          color: #FF4835;
          opacity: 0.9;
        }
      }
      .nav-sub{
        font-size: 15px;
      }
      .home {
        margin-left: 0px;
      }
      .active {
        color: #FF4835;
        cursor: auto;
      }
    }
  }
  .navs-sub{
    /*border-top: 1px solid #f2f2f2;*/
    /*border-bottom: 1px solid #f2f2f2;*/
  }
  .ivu-affix {
    top: 0px;
    left: 0px;
    width: 100%;
    position: fixed;
    background: #120D21;
    z-index: 9999;
    border-bottom: 1px solid #120D21;
  }
  .sc-width {
    width: 1600px;
  }
  @media (min-width: 1600px) {
    @max-width: 1500px;

    .search-box {
      .search-input-box {
        input {
          width: 220px;
        }
      }
    }
    .header .notif-user {
      /*margin-left: 125px;*/
    }
  }
  @media (max-width: 1600px) and (min-width: 1400px) {
    @max-width: 1400px;
    .search-box {
      .search-input-box {
        input {
          width: 260px;
        }
        .role-input {
          width: 300px;
        }
      }
    }
    .next {
      left: 1200px !important;
    }
  }
  @media (max-width: 1400px) and (min-width: 1200px) {
    @max-width: 1200px;
    .search-box {
      .search-input-box {
        input {
          width: 210px;
        }
      }
    }
    .header .notif-user {
      margin-left: 0px;
    }
    .header .name{
      display:none
    }
  }
  @media (max-width: 1200px) {
    @max-width: 1100px;
    .search-box {
      .search-input-box {
        input {
          width: 210px;
        }
      }
    }
    .header .name{
      display:none
    }
  }

  .top-menu {
    height: 40px;
    background: #f2f2f2;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .content {
      /*width: 1500px;*/
      margin-left: auto;
      margin-right: auto;
      div {
        display: inline-block;
        float: right;
        height: 17px;
        font-size: 16px;

        font-weight: 400;
        color: #999999;
        line-height: 17px;
        cursor: pointer;
      }
      .messages {
        /*margin-right:20px;*/
      }
      .conferences {
        margin-right: 20px;
      }
    }
  }
  .header-menus {
    .menu {
      cursor: pointer;
      font-size: 14px;
      font-weight: bold;
      padding: 14px 12px;
      color: #848484;
      &.active {
        color: @primary-color;
      }
    }
  }
  .search-button {
    cursor: pointer;
  }
  .search-button:hover {
    opacity: 0.9;
  }
</style>

<style lang="less" scoped>
  .header {
    background: #120D21;
    // box-shadow: 0 2px 8px #f0f1f2;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 76px;
    @height: 50px;
    // margin-bottom: 10px;
    padding: 0;
    /*width: 1500px;*/
    margin: 0 auto;
    .sell {
      display: inline-block;
      height: 20px;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: #f57f45;
      margin-right: 32px;
      position: relative;
      top: 2px;
    }
    .register-and-login{
      display: flex;
      align-items: center;
    }
    .login {
      display: inline-block;
      height: 20px;
      font-style: normal;
      font-family: "Montserrat";
        font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: #fff;
      margin-right: 20px;
      /*position: relative;*/
      /*top: 2px;*/
    }
    .register {
      display: flex;
      padding: 18px 20px;
      color: #fff;
      cursor: pointer;
      flex-shrink: 0;
      line-height: 0;
      font-family: "Montserrat";
        font-weight: 600;
      font-size: 16px;
      /*margin-right: 20px;*/
      /*width: 146px;*/
      height: 45px;
      border: 1px solid #FF4835;
      border-radius: 5px;
      background: #FF4835;

      color: #ffffff;
      justify-content: center;
      align-items: center;
    }
    .logo {
      width: 232px;
      img {
        /*height: 30px;*/
        /*padding: 10px 20px;*/
        width: 180px;
        display: block;
      }
    }
    .menus {
      display: flex;
      .menu {
        cursor: pointer;
        font-size: 14px;
        font-weight: bold;
        padding: 14px 12px;
        color: #848484;
        &.active {
          color: @primary-color;
        }
      }
    }
    .search-box {
      display: flex;
      align-items: center;
      border-radius: 50px;
      height: 39px;
      /*width: 300px;*/
      /*flex: 1;*/
      margin-right: 10px;
      .search-input-box {
        display: flex;
        border-radius: 20px 0 0 20px;
        /*flex: 1;*/
        height: 100%;
        /*background: #5d5d5d;*/
        line-height: initial;
        /*padding: 0 20px;*/
        input {
          /*width: 550px;*/
          height: 100%;
          outline: none;
          background: #f2f2f2;
          /*border: none;*/
          /*color: #fff;*/
          /*caret-color: #fff;*/
          border: none;
          /*border-right:0.5px solid #CCCCCC;*/
          padding-left: 10px;

          &::-webkit-input-placeholder {
            color: #bdcada;
          }
          &:-moz-placeholder {
            color: #bdcada;
          }
          &::-moz-placeholder {
            color: #bdcada;
          }
          &:-ms-input-placeholder {
            color: #bdcada;
          }
          position: relative;
          top: 0px;
          border-top-left-radius: 8px;
          border-bottom-left-radius: 8px;
        }
        .show-type-input {
          position: relative;
          /*top:-108px;*/
        }
        .select-box {
          display: inline-block;
          width: 125px;
          div {
            padding-left: 10px;
          }
          .top {
            border: none;
            height: 39px;
            padding-top: 9px;
            background: #f2f2f2;
            div {
              display: inline-block;
            }
            .value {
              font-weight: 500;
              font-size: 14px;
              line-height: 17px;
              color: #222222;
            }
            .img {
              position: relative;
              bottom: 2px;
            }
          }
          .show-typ-top {
            position: relative;
            top: 0px;
          }
          .select {
            position: relative;
            /*top: -4px;*/
            font-size: 18px;

            font-weight: 500;
            color: #53575c;
            line-height: 17px;
            background: #ffffff;
            div {
              height: 35px;
              padding-top: 10px;
            }
            div:hover {
              background-color: #ef7f21;
              color: #ffffff;
            }
          }
        }
        select {
          width: 140px;
          height: 100%;
          /*color: #fff;*/
          /*caret-color: #fff;*/
          border-color: #f3a565;
          border-left: none;

          /*appearance:none;*/
          /*-moz-appearance:none;*/
          /*-webkit-appearance:none;*/

          /*在选择框的最右侧中间显示小箭头图片*/
          /*background: url('../assets/down.png') no-repeat scroll right center transparent;*/
          outline: none;
          font-size: 18px;
          padding: 1px 4px 5px 11px;
        }
        .suggestion {
          min-height: 400px;
          background: #fff;
          position: relative;
          z-index: 999;
          width: 110%;
          border: 1px solid #eee;
          .hd {
            color: #666;
            font-weight: bold;
            font-size: 13px;
            padding-left: 4px;
          }
          .words {
            color: #000;
            padding: 4px 0 4px 20px;
            cursor: pointer;
            &:hover {
              background: #eee;
            }
          }
          .line {
            height: 1px;
            background: #eee;
            width: 100%;
          }
          .product {
            display: flex;
            border-bottom: 1px solid #eee;
            padding: 10px 20px;
            cursor: pointer;
            &:hover {
              background: #eee;
            }
            img {
              width: 100px;
              margin-right: 20px;
            }
            .des {
              display: flex;
              flex-direction: column;
              justify-content: center;
              .title {
                color: #000;
                font-weight: bold;
              }
              .sub-title {
                color: #999;
              }
            }
          }
        }
        .input-border {
          display: flex;
          align-items: center;
          background: #f2f2f2;
          .border {
            width: 0px;
            height: 16px;
            border: 1px solid #e0e0e0;
            /*transform: rotate(90deg);*/
          }
        }
      }
      .btn {
        border-radius: 0 20px 20px 0;
        background: @primary-color;
        color: #fff;
        padding: 0px 20px;
        display: flex;
        align-items: center;
        justify-self: center;
        line-height: initial;
        height: 100%;
        cursor: pointer;
      }
      .search-btn {
        width: 50px;
        height: 38px;
        background: #ef7f21;
        border-radius: 0px 8px 8px 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          width: 24px;
          height: 24px;
          position: absolute;
          margin-top: 8px;
          margin-left: 28px;
        }
      }
    }
    .notif-user {
      .sell {
        width: 70px;
      }
      display: flex;
      /*margin-left:125px;*/
    }

    .notif {
      position: relative;
      // position: absolute;
      // top: 20px;
      // right: 200px;
      margin-left: auto;
      height: 26px;
      line-height: 25px;
      font-size: 13px;
      padding: 0 5px;
      /*border-radius: 5px;*/
      color: @primary-color;
      margin-top: 13px;
      span {
        cursor: pointer;
      }
      strong.dian {
        position: absolute;
        top: -8px;
        left: 15px;
        color: #ef2621;
        font-size: 41px;
      }
      .ivu-icon {
        font-size: 24px;
        cursor: pointer;
        background-image: url("../assets/bell.png");
      }
      .lists-wrap {
        position: absolute;
        top: 24px;
        right: -2px;
        padding-top: 17px;
        width: 230px;
      }
      .drop-lists {
        width: 230px;
        background-color: #4a4a4a;
        list-style: none;
        overflow: hidden;
        padding: 5px 0;
        box-sizing: border-box;
        border-radius: 4px;
        box-shadow: 0 1px 6px rgba(0, 0, 0, 0.2);
        position: absolute;
        z-index: 900;
        li {
          word-break: break-word;
          color: #d2d2d2;
          font-size: 14px;
          margin: 0;
          line-height: normal;
          padding: 7px 16px 10px;
          clear: both;
          list-style: none;
          transition: background 0.2s ease-in-out;
          border-bottom: 1px solid #3a3a3a;
          strong {
            color: #f8f8f8;
          }
          .time {
            padding: 6px 0 4px;
          }
        }
        li.view {
          border: none;
          text-align: center;
          color: @primary-color;
          padding-bottom: 5px;
          cursor: pointer;
        }
        a {
          font-weight: bold;
          color: @primary-color;
          border-bottom: 1px solid @primary-color;
        }
      }
    }
    .name {
      margin: 0 10px;
      font-size: 18px;

      font-weight: 500;
      color: #fff;
      line-height: 18px;
      padding-top: 17px;
    }
    .dropmenu {
      .dropdown {
        display: flex;
        align-items: center;
        font-size: 20px !important;
        height: 50px;
        /*padding-left:20px;*/
        cursor: pointer;
        color: #ef7f21;

        .icon {
          /*margin: 0 10px;*/
          /*color: rgb(242, 242, 242);*/
          font-size: 14px;
        }
      }
      /deep/ .ivu-select-dropdown {
        background-color: #4a4a4a;
        .ivu-dropdown-item {
          color: #d2d2d2;
          cursor: pointer;
          &:hover {
            background-color: #777;
          }
        }
      }
    }
  }
  .search-val {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #222222;
  }
  .user-avatar-n{
    width: 38px !important;
    height: 38px !important;
    font-size: 14px !important;
    font-weight: 500 !important;
  }
  .user-avatar-n-down{
    width: 65px !important;
    height: 65px !important;
    font-size: 18px !important;
    font-weight: 500 !important;
  }
  .dropdown-menu{
    position: absolute;
    left: -160%;
    width: 255px;
    height: auto;
    background: #FFFFFF;
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    .logo{
      display: flex;
      justify-content: center;
      img{
        width: 69px;
        height: 69px;
        border-radius: 50%;
        /*border: 1px solid #252181;*/
      }

      margin: 32px auto 14px auto;
    }
    .name{
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      /* identical to box height, or 143% */

      text-align: center;

      color: #252525;
    }
    .email{
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      /* identical to box height, or 143% */


      color: #252525;
      display: flex;
      justify-content: center;
      margin: 5px auto 26px auto;

    }
    .line{
      width: 255px;
      height: 0px;
      left: 1171px;
      top: 266px;

      border: 1px solid #ECEBEB;
    }
    a:hover{
      text-decoration: none ;
    }
    .item-name{
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      /* or 143% */

      display: flex;
      align-items: center;

      color: #252525;
      cursor: pointer;
      height: 60px;
      padding-left: 30px;

    }
    .logout{
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      /* or 143% */

      display: flex;
      align-items: center;

      color: #252181;
      cursor: pointer;
      height: 60px;
      padding-left: 30px;
    }
  }
  .request-demo-link{
    .request-demo{
      padding: 3px 12px;
      border: 1px solid #fff;
      height: 45px;
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 20px;
      font-weight: 600;
      font-size: 16px;
      border-radius: 5px;
      &:hover{
        background: #FF4835;
        border: 1px solid #FF4835;
        transition: all 0.2s linear;
      }
    }
  }
</style>
