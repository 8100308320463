<template>
  <div>
    <Modal class="pop-register-email" footer-hide v-model="showLogin">
      <div class="pop-login-main">
        <div class="top">
          <div class="logo">
            <img src="@/assets/logo.png" alt="" />
          </div>
          <div class="title">Welcome to Connexima</div>
        </div>
        <div class="desc"></div>
        <div class="email content-input">
          <div class="title">Email<span class="red-point">*</span></div>
          <div class="input">
            <input
              placeholder="Work email address"
              type="text"
              v-model="loginForm.email"
              :readonly="step > 0"
            />
          </div>
        </div>
        <div class="email content-input" v-if="step === 1">
          <div class="title">
            Verification Code<span class="red-point">*</span>
          </div>
          <div class="input">
            <inputCode ref="inputCode"></inputCode>
          </div>
        </div>
        <template v-if="step === 2">
          <div class="email content-input content-input-flex">
            <div class="item">
              <div class="title">
                First Name<span class="red-point">*</span>
              </div>
              <div class="input">
                <input
                  placeholder=""
                  type="text"
                  v-model="loginForm.first_name"
                />
              </div>
            </div>
            <div class="item">
              <div class="title">Last Name<span class="red-point">*</span></div>
              <div class="input">
                <input
                  placeholder=""
                  type="text"
                  v-model="loginForm.last_name"
                />
              </div>
            </div>
          </div>
          <div class="email content-input content-input-flex">
            <div class="item">
              <div class="title">
                Company Name<span class="red-point">*</span>
              </div>
              <div class="input">
                <input
                  placeholder=""
                  type="text"
                  v-model="loginForm.company_name"
                />
              </div>
            </div>
            <div class="item">
              <div class="title">Country<span class="red-point">*</span></div>
              <div class="input">
                <select v-model="loginForm.company_country_address">
                  <option
                    v-for="item in countryList"
                    :key="item.id"
                    :value="item.id"
                    >{{ item.name }}</option
                  >
                </select>
              </div>
            </div>
          </div>
          <div class="email content-input">
            <div class="title">Website</div>
            <div class="input input-website">
              <select v-model="loginForm.company_http_str">
                <option value="https://">Https://</option>
                <option value="http://">Http://</option>
                >
              </select>
              <input
                placeholder=""
                type="text"
                v-model="loginForm.websiteContent"
              />
            </div>
          </div>
          <div class="password content-input content-input-flex">
            <div class="item">
              <div class="title">Password<span class="red-point">*</span></div>
              <div class="input">
                <input
                  placeholder="Minimum 6 characters"
                  type="password"
                  v-model="loginForm.password"
                />
              </div>
            </div>
            <div class="item">
              <div class="title">
                Confirm Password<span class="red-point">*</span>
              </div>
              <div class="input">
                <input
                  placeholder="Minimum 6 characters"
                  type="password"
                  v-model="loginForm.confirm_password"
                />
              </div>
            </div>
          </div>
        </template>
        <div class="go-back" v-if="step === 1">
          Sent to wrong email? <span @click="goBack">Go back</span>
        </div>
        <div
          :class="[
            'login-submit',
            'search-button',
            { 'login-submit-waiting': loginSubmitWaiting }
          ]"
          @click="register"
        >
          <div class="word">
            {{ signInText }}
          </div>

          <div class="continue-icon" v-if="step < 2">
            <svg
              width="20"
              height="20"
              viewBox="0 0 50 50"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M17.8122 5.20825L14.583 8.43742L31.1455 24.9999L14.583 41.5624L17.8122 44.7916L37.4997 24.9999L17.8122 5.20825Z"
                fill="#fff"
              />
            </svg>
          </div>
        </div>
        <div class="sign-in-with">
          <div class="word">
            <div class="left-inline"></div>
            <div class="word-content">
              or sign up with
            </div>
            <div class="left-inline"></div>
          </div>
          <div class="icons">
            <div class="icon" @click="socialLogin('google')">
              <img
                src="@/assets/register/google.png"
                v-if="!googleLoginLoading"
              />
              <img src="@/assets/loading.gif" v-else />
            </div>
            <div class="icon" @click="socialLogin('facebook')">
              <img
                src="@/assets/register/facebook.png"
                v-if="!facebookLoginLoading"
              />
              <img src="@/assets/loading.gif" v-else />
            </div>
            <div class="icon" @click="socialLogin('linkedin')">
              <img
                src="@/assets/register/linkedin.png"
                v-if="!linkedinLoginLoading"
              />
              <img src="@/assets/loading.gif" v-else />
            </div>
          </div>
        </div>
        <div class="register">
          Already a member?
          <span @click="login" style="">Login here</span>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import inputCode from "@/components/inputCode";

export default {
  props: [],
  data() {
    return {
      loginForm: {
        role: "exhibitor",
        email: "",
        password: "",
        company_name: "",
        company_country_address: 1,
        confirm_password: "",
        first_name: "",
        last_name: "",
        website: "",
        company_http_str: "https://",
        websiteContent: "",
        uniq_str: ""
      },
      showLogin: false,
      signInText: "Continue",
      loginSubmitWaiting: false,
      countryList: [
        { id: 1, name: "United States" },
        { id: 2, name: "Canada" },
        { id: 45, name: "China" }
      ],
      step: 0,
      googleLoginLoading: false,
      facebookLoginLoading: false,
      linkedinLoginLoading: false
    };
  },
  components: { inputCode },
  mounted() {
    this.getCountry();
  },
  methods: {
    socialLogin(type) {
      if (type === "google") {
        this.googleLoginLoading = true;
      }
      if (type === "facebook") {
        this.facebookLoginLoading = true;
      }
      if (type === "linkedin") {
        this.linkedinLoginLoading = true;
      }
      this.$store
        .dispatch("user/auth", {
          type: type
        })
        .then(res => {
          window.location.href = res;
        })
        .catch(({ message }) => {
          this.$Message.error(message);
        })
        .finally(() => {
          if (type === "google") {
            this.googleLoginLoading = false;
          }
          if (type === "facebook") {
            this.facebookLoginLoading = false;
          }
          if (type === "linkedin") {
            this.linkedinLoginLoading = false;
          }
        });
    },
    authEmailCode() {
      this.loginSubmitWaiting = true;
      this.signInText = "Submitting";
      let inputVal = this.$refs.inputCode.inputbox;
      var inputValData = "";
      inputVal.forEach(item => {
        inputValData = inputValData + item.value;
      });
      this.$store
        .dispatch("user/authEmailCode", {
          email: this.loginForm.email,
          code: inputValData
        })
        .then(res => {
          console.log(res);
          this.step++;
          this.signInText = "Sign up";
        })
        .catch(({ message }) => {
          this.signInText = "Continue";
          this.$Message.error(message);
        })
        .finally(() => {
          this.loginSubmitWaiting = false;
        });
    },
    authEmail() {
      this.loginSubmitWaiting = true;
      this.signInText = "Submitting";
      this.$store
        .dispatch("user/authEmail", {
          email: this.loginForm.email
        })
        .then(res => {
          this.loginForm.uniq_str = res.uniq_str;
          this.step++;
        })
        .catch(({ message }) => {
          this.$Message.error(message);
        })
        .finally(() => {
          this.loginSubmitWaiting = false;
          this.signInText = "Continue";
        });
    },
    goBack() {
      this.step--;
    },
    login() {
      this.$emit("login");
    },
    changeRole(role) {
      this.loginForm.role = role;
    },
    getCountry() {
      this.$store.dispatch("user/getRegisterCountry").then(res => {
        this.countryList = res;
      });
    },
    register() {
      const {
        loginForm: {
          email,
          password,
          role,
          company_name,
          country,
          confirm_password,
          first_name,
          last_name
        }
      } = this;
      if (role == "") {
        this.$Message.error("Please choose account type!");
        return;
      }
      if (this.step === 0) {
        if (email == "") {
          this.$Message.error("Please enter your email!");
          return;
        }
        this.authEmail();
        return;
      }

      if (this.step === 1) {
        if (email == "") {
          this.$Message.error("Please enter your email!");
          return;
        }
        this.authEmailCode();
        return;
      }

      if (email == "" || password == "") {
        this.$Message.error("Please enter your email and password!");
        return;
      }
      if (first_name == "" || last_name == "") {
        this.$Message.error("Please enter your first name and last name!");
        return;
      }
      if (company_name == "" || country == "") {
        this.$Message.error("Please enter company name and country!");
        return;
      }
      if (password !== confirm_password) {
        this.$Message.error("Confirm password error!");
        return;
      }
      this.loading = true;
      this.loginSubmitWaiting = true;
      this.signInText = "registering";
      if (this.loginForm.websiteContent !== "") {
        this.loginForm.website =
          this.loginForm.company_http_str + this.loginForm.websiteContent;
      }
      this.$store
        .dispatch("user/register", this.loginForm)
        .then(() => {
          this.$Message.success("Login successful!");
          this.loginSubmitWaiting = false;
          this.showLogin = false;
          this.loginForm = {
            role: "exhibitor",
            email: "",
            password: "",
            company_name: "",
            company_country_address: 1,
            confirm_password: "",
            first_name: "",
            last_name: "",
            website: "",
            websiteContent: "",
            company_http_str: "https://"
          };
        })
        .catch(({ message }) => {
          this.signInText = "Create Account";
          this.loginSubmitWaiting = false;
          this.$Message.error(message);
          this.step = 0;
        })
        .finally(() => {});
    }
  }
};
</script>
<style lang="less" scoped>
.pop-login-main {
  /*height: 770px;*/
  display: flex;
  flex-direction: column;
  align-items: center;
  .top {
    .logo {
      display: flex;
      justify-content: center;
      margin-top: 20px;
      margin-bottom: 20px;
      img {
        width: 170px;
        height: 29px;
      }
    }
    .title {
      font-weight: 600;
      font-size: 22px;
      line-height: 37px;
      display: flex;
      align-items: center;
      text-align: center;

      /* Black */

      color: #222222;
      margin-top: 5px;
    }
    .close {
    }
  }
  .desc {
    /*margin: 10px auto 18px auto;*/
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
    /* or 144% */

    display: flex;
    align-items: center;
    text-align: center;

    /* Gray 3 */

    color: #828282;
  }
  .type {
    .type-title {
      font-weight: 600;
      font-size: 20px;
      line-height: 28px;
      /* identical to box height, or 140% */

      display: flex;
      align-items: center;

      color: #000000;
      margin-bottom: 10px;
    }
    .types {
      display: flex;
      justify-content: space-between;
      width: 400px;
      .active-buyer {
        background: #f99d20;
        .buyer-name {
          color: #f2f2f2 !important;
        }
      }
      .buyer {
        display: flex;
        width: 230px;
        height: 50px;
        border: 1px solid #f57f45;
        box-sizing: border-box;
        border-radius: 8px;
        .buyer-icon {
          display: flex;
          flex-direction: column;
          justify-content: center;
          margin-left: 15px;
        }
        .buyer-name {
          font-weight: 600;
          font-size: 16px;
          line-height: 20px;
          /* identical to box height */

          display: flex;
          align-items: center;
          text-align: center;

          /* Black */

          color: #222222;

          /* Inside Auto Layout */

          flex: none;
          order: 1;
          flex-grow: 0;
          margin: 0px 16px;
        }
      }

      .active-seller {
        background: #f99d20;
        .seller-name {
          color: #f2f2f2 !important;
        }
      }
      .seller {
        display: flex;
        width: 230px;
        height: 50px;
        border: 1px solid #f57f45;
        box-sizing: border-box;
        border-radius: 8px;
        .seller-icon {
          display: flex;
          flex-direction: column;
          justify-content: center;
          margin-left: 15px;
        }
        .seller-name {
          font-weight: 600;
          font-size: 16px;
          line-height: 20px;
          /* identical to box height */

          display: flex;
          align-items: center;
          text-align: center;

          /* Black */

          color: #222222;

          /* Inside Auto Layout */

          flex: none;
          order: 1;
          flex-grow: 0;
          margin: 0px 16px;
        }
      }
    }
  }
  .content-input {
    .title {
      font-weight: 600;
      font-size: 16px;
      line-height: 26px;
      /* identical to box height, or 140% */

      display: flex;
      align-items: center;

      color: #000000;
      margin: 15px auto 10px auto;
      .red-point {
        color: red;
        position: relative;
        top: 4px;
        left: 3px;
        font-size: 18px;
      }
    }
    .input {
      input {
        width: 470px;
        height: 44px;
        left: 527px;
        top: 702px;

        /* Gray 6 */

        /*background: #f2f2f2;*/
        border-radius: 8px;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        /* or 150% */

        display: flex;
        align-items: center;

        /* Gray 3 */

        color: #000;
        border: none;
        outline: none;
        padding-left: 10px;
        border: 1px solid #cbc8c8;
      }
      select {
        width: 225px;
        height: 44px;
        left: 527px;
        top: 702px;

        /* Gray 6 */

        /*background: #f2f2f2;*/
        border-radius: 8px;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        /* or 150% */

        display: flex;
        align-items: center;

        /* Gray 3 */

        color: #000;
        border: none;
        outline: none;
        padding-left: 10px;
        border: 1px solid #cbc8c8;
      }
      input:focus {
        background: #fff;
        border: 1px solid #f57f45;
      }
    }
  }
  .content-input-flex {
    display: flex;
    justify-content: space-between;
    width: 470px;
    .input input {
      width: 225px;
    }
  }
  .forgot {
    font-weight: 600;
    font-size: 16px;
    line-height: 26px;
    /* or 162% */

    display: flex;
    align-items: center;
    text-align: center;
    justify-content: right;
    width: 470px !important;

    /* Gray 1 */

    color: #333333;
    margin: 16px auto;
  }
  .login-submit {
    display: flex;
    /*flex-direction: column;*/
    justify-content: center;
    align-items: center;
    padding: 0px 24px;
    width: 470px;
    height: 45px;
    background: #f57f45;
    /* Shadow */

    box-shadow: 4px 4px 20px rgba(34, 34, 34, 0.1);
    border-radius: 8px;

    cursor: pointer;
    border: none;
    margin-top: 30px;
    .word {
      font-weight: 700;
      color: #ffffff;
      text-align: center;
      font-size: 16px;
      /* padding-top: 10px; */
    }
    .continue-icon {
      position: relative;
      top: 3px;
      left: 3px;
      svg{
        width: 20px;
        height: 20px;
      }
    }
  }
  .login-submit-waiting {
    opacity: 0.7;
  }
  .register {
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    /* or 162% */

    display: flex;
    align-items: center;
    text-align: center;

    /* Gray 3 */

    color: #828282;
    margin-top: 30px;
    .register-here {
      color: #222222;
      font-weight: 600;
      margin-left: 5px;
    }
    span {
      font-weight: 600;
      font-size: 14px;
      color: #222;
      cursor: pointer;
      position: relative;
      bottom: 1px;
      left: 3px;
    }
  }
}
.input-website {
  display: flex;
  input {
    width: 363px !important;
    border-left: none !important;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
  }
  select {
    width: 105px !important;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }
}
.sign-in-with {
  position: relative;
  top: 15px;
  .word {
    display: flex;
    justify-content: space-between;
    width: 470px;
    .left-inline {
      height: 1px;
      background: #ddd;
      width: 125px;
      position: relative;
      top: 10px;
    }
  }
  .icons {
    display: flex;
    width: 185px;
    justify-content: space-between;
    margin: 15px auto 0 auto;
    .icon {
      cursor: pointer;
      img {
        width: 27px;
      }
    }
  }
}
.go-back {
  position: relative;
  top: 17px;
  left: 110px;
  font-size: 14px;
  span {
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
  }
}
@media (max-width: 800px) {
  .pop-login-main {
    .top{
      .logo{
        img{
          width: 150px;
          height: 25px;
        }
      }
      .title{
        font-size: 18px;
        line-height: 35px;
      }
      .desc{
        font-size: 16px;
        line-height: 22px;
      }

    }
    .login-submit {
      width: 100%;
    }

    .content-input {
      .title{
        font-size: 16px;
        line-height: 24px;
      }
      width: 90%;

      .input input {
        width: 100%;
        height: 38px;
      }
      .input select {
        height: 38px;
      }
    }
    .content-input-flex {
      display: block !important;
    }
    .login-submit {
      width: 90%;
      height: 38px;
    }
  }
  .sign-in-with {
    width: 90%;
    .word {
      width: 100%;
      .left-inline {
        width: 80px;
      }
    }
  }
  .go-back {
    left: 40px;
  }

}
</style>

<style lang="less">
.pop-register-email {
  .ivu-modal {
    width: 570px !important;
  }
}

@media (max-width: 800px) {
  .pop-register-email {
    .ivu-modal {
      width: 90% !important;
      margin: 0 auto;
    }
  }
}
</style>
