import {
  getToken,
  setToken,
  setTokenExpiresSec,
  getTokenExpiresSec,
  removeLocalInfo
} from "@/utils/helper";
import api from "@/api";
import moment from "moment-timezone";
const {
  _login,
  _logout,
  _queryMe,
  // _queryNotification,
  _notifiReadAll,
  _register,
  _reSendActiveEmail,
  _country,
  _socialAuth,
  _getInfoViaUniqStr,
  _authRegister,
  _postAuthEmail,
  _postAuthEmailCode
} = api;

const state = {
  notifShow: false,
  notifLists: [],
  token: getToken(),
  expiresSec: getTokenExpiresSec(),
  timezone: moment.tz.guess(),
  is_administrator: false,
  user_id: "",
  first_name: "",
  last_name: "",
  login_logs_count: "",
  company_name: "",
  company_id: "",
  email: "",
  avatar: "",
  role: ""
};

const mutations = {
  common(state, payload) {
    Object.keys(payload).map(key => {
      state[key] = payload[key];
    });
  },
  setNotifShow(state, val) {
    state.notifShow = val;
  }
};

const actions = {
  queryMe({ commit }) {
    return _queryMe().then(response => {
      const { data } = response;
      commit("common", { first_name: data.first_name });
      commit("common", { last_name: data.last_name });
      commit("common", { login_logs_count: data.login_logs_count });
      commit("common", { company_name: data.company.company_name });
      commit("common", { company_id: data.company_id });
      commit("common", { full_name: data.first_name + ' ' +  data.last_name});
      commit("common", {
        company_addr:
          (data.company.company_street_address || "") +
          " " +
          (data.company.company_city_address || "") +
          " " +
          (data.company.company_state_address || "") +
          " " +
          data.company.company_country_address_desc
      });
      commit("common", { email: data.email });
      commit("common", { avatar: data.avatar?.url });
      commit("common", { phone: data.phone });
      commit("common", { timezone: data.timezone });
      commit("common", {
        availability_time: data.availability_time
      });
      commit("common", { role: data.company.role });
      commit("common", { is_administrator: data.is_administrator });
      commit("common", { user_id: data.id });
    });
  },

  queryNotification({ commit }) {
    // _queryNotification().then(response => {
    //   const { data } = response;
    //   data.data.forEach(item => {
    //     var localTime = moment
    //       .utc(item.created_at)
    //       .local()
    //       .format("YYYY-MM-DD HH:mm:ss");
    //     var lastTime = new Date().getTime() - new Date(localTime).getTime();
    //     var days = 0;
    //     var hours = 0;
    //     var mins = 0;
    //     days = parseInt(lastTime / (24 * 60 * 60 * 1000));
    //     hours = parseInt(lastTime / (60 * 60 * 1000));
    //     if (days > 0) {
    //       item.created_at = days + " days ago";
    //     } else if (hours > 0) {
    //       mins = parseInt(lastTime / (60 * 60 * 1000));
    //       item.created_at = mins + " hours ago";
    //     } else {
    //       mins = parseInt(lastTime / (60 * 1000));
    //       item.created_at = mins + " min ago";
    //     }
    //   });
    //   commit("common", { notifLists: data.data.slice(0, 3) });
    // });
  },

  notifiReadAll() {
    _notifiReadAll();
  },

  login({ commit }, data) {
    return _login(data).then(response => {
      const { data } = response;
      commit("common", { first_name: data.user.first_name });
      commit("common", { last_name: data.user.last_name });
      commit("common", { full_name: data.user.first_name + ' ' +data.user.last_name });
      commit("common", { login_logs_count: data.user.login_logs_count });
      commit("common", { company_name: data.user.company.company_name });
      commit("common", { company_id: data.user.company_id });
      commit("common", { email: data.user.email });
      commit("common", { avatar: data.user.avatar?.url });
      commit("common", { phone: data.user.phone });
      commit("common", { timezone: data.user.timezone });
      commit("common", {
        availability_time: data.user.availability_time
      });
      commit("common", { role: data.user.company.role });
      commit("common", { is_administrator: data.user.is_administrator });
      commit("common", { user_id: data.user.id });
      commit("common", { country: data.user.company.company_country_address });
      commit("common", { website: data.user.company.website });

      const curSec = parseInt(new Date().getTime() / 1000, 10);
      const expiresSec = curSec + data.expires_in - 10; // 10 sec before

      commit("common", { expiresSec });
      commit("common", { token: data.token });

      setTokenExpiresSec(expiresSec);
      setToken(data.token);
      return data;
    });
  },
  socialLogin({ commit }, data) {
    commit("common", { first_name: data.user.first_name });
    commit("common", { last_name: data.user.last_name });
    commit("common", { full_name: data.user.first_name + ' ' +data.user.last_name });
    commit("common", { login_logs_count: data.user.login_logs_count });
    commit("common", { company_name: data.user.company.company_name });
    commit("common", { company_id: data.user.company_id });
    commit("common", { email: data.user.email });
    commit("common", { avatar: data.user.avatar?.url });
    commit("common", { phone: data.user.phone });
    commit("common", { timezone: data.user.timezone });
    commit("common", {
      availability_time: data.user.availability_time
    });
    commit("common", { role: data.user.company.role });
    commit("common", { is_administrator: data.user.is_administrator });
    commit("common", { user_id: data.user.id });
    commit("common", { country: data.user.company.company_country_address });
    commit("common", { website: data.user.company.website });

    const curSec = parseInt(new Date().getTime() / 1000, 10);
    const expiresSec = curSec + data.expires_in - 10; // 10 sec before

    commit("common", { expiresSec });
    commit("common", { token: data.token });

    setTokenExpiresSec(expiresSec);
    setToken(data.token);
    return data;
  },
  register({ commit }, data) {
    return _register(data).then(response => {
      let data = response;
      commit("common", { first_name: data.user.first_name });
      commit("common", { last_name: data.user.last_name });
      commit("common", { login_logs_count: data.user.login_logs_count });
      commit("common", { company_name: data.user.company.company_name });
      commit("common", { company_id: data.user.company_id });
      commit("common", { email: data.user.email });
      commit("common", { avatar: data.user.avatar?.url });
      commit("common", { phone: data.user.phone });
      commit("common", { timezone: data.user.timezone });
      commit("common", {
        availability_time: data.user.availability_time
      });
      commit("common", { role: data.user.company.role });
      commit("common", { is_administrator: data.user.is_administrator });
      commit("common", { user_id: data.user.id });
      commit("common", { country: data.user.company.company_country_address });
      commit("common", { website: data.user.company.website });

      const curSec = parseInt(new Date().getTime() / 1000, 10);
      const expiresSec = curSec + data.expires_in - 10; // 10 sec before

      commit("common", { expiresSec });
      commit("common", { token: data.token });

      setTokenExpiresSec(expiresSec);
      setToken(data.token);
      return data;
    });
  },
  authEmailCode({ commit }, data) {
    return _postAuthEmailCode(data).then(response => {
      const { data } = response;
      return data;
    });
  },
  authEmail({ commit }, data) {
    return _postAuthEmail(data).then(response => {
      const { data } = response;
      return data;
    });
  },
  authRegister({ commit }, data) {
    return _authRegister(data).then(response => {
      let data = response;
      commit("common", { first_name: data.user.first_name });
      commit("common", { last_name: data.user.last_name });
      commit("common", { login_logs_count: data.user.login_logs_count });
      commit("common", { company_name: data.user.company.company_name });
      commit("common", { company_id: data.user.company_id });
      commit("common", { email: data.user.email });
      commit("common", { avatar: data.user.avatar?.url });
      commit("common", { phone: data.user.phone });
      commit("common", { timezone: data.user.timezone });
      commit("common", {
        availability_time: data.user.availability_time
      });
      commit("common", { role: data.user.company.role });
      commit("common", { is_administrator: data.user.is_administrator });
      commit("common", { user_id: data.user.id });
      commit("common", { country: data.user.company.company_country_address });
      commit("common", { website: data.user.company.website });

      const curSec = parseInt(new Date().getTime() / 1000, 10);
      const expiresSec = curSec + data.expires_in - 10; // 10 sec before

      commit("common", { expiresSec });
      commit("common", { token: data.token });

      setTokenExpiresSec(expiresSec);
      setToken(data.token);
      return data;
    });
  },
  reSendVerifyEmail({ commit }, data) {
    return _reSendActiveEmail(data).then(response => {
      const { data } = response;
      return data;
    });
  },
  getInfoViaUniqStr({ commit }, data) {
    return _getInfoViaUniqStr(data).then(response => {
      const { data } = response;
      return data;
    });
  },
  getRegisterCountry({ commit }) {
    return _country().then(response => {
      const { data } = response;
      return data;
    });
  },
  auth({ commit }, data) {
    return _socialAuth({ param: data.type }).then(response => {
      const { data } = response;
      return data;
    });
  },
  exit({ commit }) {
    return new Promise(resolve => {
      commit("common", { token: "" });
      removeLocalInfo();
      resolve();
    });
  },

  logout({ commit }) {
    return _logout().finally(() => {
      commit("common", { token: "" });
      commit("common", { first_name: null });
      commit("common", { last_name: null });
      commit("common", { avatar: null });
      commit("common", { role: null });
      commit("common", { full_name: null });
      removeLocalInfo();
    });
  }
};

export default {
  namespaced: true,
  state,
  mutations,
  actions
};
